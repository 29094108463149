import { MultiSelect } from "react-multi-select-component";

const MultiSelectSearch = (props) => {
  const filterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ label }) => label && label.match(re));
  };

  return (
    <div>
      <MultiSelect
        filterOptions={filterOptions}
        hasSelectAll={false}
        options={props.options}
        value={props.selected}
        onChange={props.handleChange}
        labelledBy={props.label}
      />
    </div>
  );
};

export default MultiSelectSearch;
