/* eslint-disable */

export default function HuntTheme(props) {
  return (
    <>
      {props.options.map((item, index) => (
        <div key={index}>
          <h4 style={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.name}
          </h4>
          <div
            style={{ display: "block", textAlign: "center", margin: "0px 4px" }}
          >
            <label>Color: </label>
            <input
              type="color"
              onChange={(e) => props.handleChange(e, item.name, "color")}
              value={props.theme[item.name]["color"]}
            />
          </div>
          <br />
          <br />
          <div style={{ display: "block", textAlign: "center" }}>
            <label>BG: </label>
            <input
              type="color"
              onChange={(e) => props.handleChange(e, item.name, "bg")}
              value={props.theme[item.name]["bg"]}
            />
          </div>
        </div>
      ))}
    </>
  );
}
