/* eslint-disable */

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

export default function LoadingBtn(props) {
  const theme = createTheme({
    direction: props.lang === "Hebrew" ? "rtl" : "ltr",
  });
  // Create rtl cache
  const cacheRtl = createCache({
    key: props.lang === "Hebrew" ? "muirtl" : "muiltr",
    stylisPlugins: props.lang === "Hebrew" && [prefixer, rtlPlugin],
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <LoadingButton
          dir={props.lang === "Hebrew" ? "rtl" : "ltr"}
          type={props.type}
          loading={props.loading}
          variant="outlined"
          style={props.style}
          onClick={props.onClick}
          loadingPosition={"end"}
          endIcon={props.customIcon ? props.customIcon : <SendIcon />}
        >
          {props.txt}
        </LoadingButton>
      </ThemeProvider>
    </CacheProvider>
  );
}
