import { useRef, useEffect } from "react";

export default function AutoPlaySilentVideo(props) {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <video
      height={props.height}
      className={props.className}
      ref={videoRef}
      loop
      autoPlay
      muted
      playsInline
      style={{ maxWidth: "100%" }}
    >
      <source src={props.video} type="video/mp4" />
    </video>
  );
}
