/* eslint-disable */

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./App";
import AdminPanel from "./components/admin/AdminPanel";
import Login from "./components/auth/Login";
import { checkUser } from "./hooks/checkUser";
import axios from "axios";
import { SERVER_URL, AUTH_API } from "./Config";
import NotFound from "./components/view/NotFound";
import Home from "./components/view/Home";
import Post from "./components/view/Post";

// let isUser = checkUser();

const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <Home />,
    // element: <Navigate to="https://clg.co.il" replace />,
    errorElement: <NotFound />,
  },
  {
    path: "/game/:id",
    element: <App />,
    errorElement: <NotFound />,
  },
  {
    path: "/admin-panel/*",
    element: <AdminPanel />,
  },
  {
    path: "/hunts/:id",
    element: <AdminPanel />,
  },
  {
    path: "/post/:id",
    element: <Post />,
  },
  // {
  //   path: "/new-hunt",
  //   element: <AdminPanel />,
  // },
  // {
  //   path: "/challenges",
  //   element: <AdminPanel />,
  // },
  // {
  //   path: "/tasks",
  //   element: <AdminPanel />,
  // },
  // {
  //   path: "/create-qr/:hash",
  //   element: <CreateQr menu={["New Hunt", "Manage Hunts"]} />,
  // },
  //   {
  //     path: "/scan-qr/:id",
  //     element: <CreateQr menu={["New Hunt", "Manage Hunts"]} />,
  //   },
  //   {
  //     path: "*",
  //     element: <App menu={["My Hunts"]} />,
  //   },
]);

export default router;
