/* eslint-disable */

import { useState, useEffect } from "react";
import {
  FaUsers,
  FaUserNinja,
  FaEye,
  FaArrowAltCircleLeft,
} from "react-icons/fa";
import FlipCard from "../partials/FlipCard";
import LoadingBtn from "../MultiLang/LoadingBtn";
import MultiTextField from "../MultiLang/TextField";

export default function PlayerType(props) {
  const [playerName, setPlayerName] = useState("");
  const [watcherName, setWatcherName] = useState("");
  const [error, setError] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState("");

  useEffect(() => {
    props.teamOptions[0] && setSelectedTeam(props.teamOptions[0].name);
  }, []);

  const localization = [
    {
      name: "playerName",
      English:
        props.hunt.individual_name && props.hunt.individual_name != "undefined"
          ? `Enter ${props.hunt.individual_name}`
          : "Player Name",
      Hebrew:
        props.hunt.individual_name && props.hunt.individual_name != "undefined"
          ? `כניסה ל${props.hunt.individual_name}`
          : "שם שחקן",
    },
    {
      name: "chooseTeam",
      English: "Choose Team",
      Hebrew: "בחר/י צוות",
    },
    {
      name: "watcherName",
      English: "Watcher Name",
      Hebrew: "שם צופה",
    },
    {
      name: "props.enterGame",
      English: "Enter Game",
      Hebrew: "כניסה למשחק",
    },
    {
      name: "noTeams",
      English: "No teams to join",
      Hebrew: "אין צוותים במשחק זה",
    },
  ];

  const types = [
    {
      value: "individual",
      Hebrew:
        props.hunt.individual_name && props.hunt.individual_name != "undefined"
          ? props.hunt.individual_name
          : "שחק כיחיד",
      English:
        props.hunt.individual_name && props.hunt.individual_name != "undefined"
          ? props.hunt.individual_name
          : "Play as individual",
      disabled: !props.hunt.has_individuals,
      icon: (
        <FaUserNinja
          size={40}
          style={{ fill: props.hunt.hunt_theme.boxes.color }}
        />
      ),
      component: (
        <div
          className="iconBox"
          style={{
            margin: "10px auto",
            width: props.isTabletOrMobile ? "80%" : "50%",
            backgroundColor: props.hunt.hunt_theme.boxes.bg,
            border: "3px solid",
            borderColor: props.hunt.hunt_theme.boxes.color,
          }}
        >
          <MultiTextField
            lang={props.lang}
            label={
              localization.find((l) => l.name === "playerName")[props.lang]
            }
            placeholder={
              localization.find((l) => l.name === "playerName")[props.lang]
            }
            value={playerName}
            onChange={(e) => {
              props.setLoginError(null);
              setPlayerName(e.target.value);
            }}
          />
          <div style={{ textAlign: "center" }}>
            <LoadingBtn
              lang={props.lang}
              style={{
                width: "100%",
                margin: "10px auto 00px auto",
                backgroundColor: props.hunt.hunt_theme.header.bg,
                color: props.hunt.hunt_theme.header.color,
                borderColor: props.hunt.hunt_theme.header.bg,
              }}
              txt={
                localization.find((l) => l.name === "props.enterGame")[
                  props.lang
                ]
              }
              loading={props.submitLoading}
              onClick={(e) => props.enterGame(e, "individual", playerName)}
              type="submit"
              customIcon={props.lang === "Hebrew" && <FaArrowAltCircleLeft />}
            />
            {props.loginError && (
              <span className="error" style={{ fontSize: "12px" }}>
                {props.loginError}
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      value: "team",
      Hebrew:
        props.hunt.teams_name && props.hunt.teams_name != "undefined"
          ? `הצטרף ל${props.hunt.teams_name}`
          : "הצטרף לצוות",
      English:
        props.hunt.teams_name && props.hunt.teams_name != "undefined"
          ? `Join the ${props.hunt.teams_name}`
          : "Join a team",
      disabled: !props.hunt.has_teams,
      icon: (
        <FaUsers
          size={40}
          style={{ fill: props.hunt.hunt_theme.boxes.color }}
        />
      ),
      component: (
        <div
          onMouseEnter={(e) => e.preventDefault()}
          className="iconBox"
          style={{
            margin: "10px auto",
            width: props.isTabletOrMobile ? "80%" : "50%",
            backgroundColor: props.hunt.hunt_theme.boxes.bg,
            border: "3px solid",
            borderColor: props.hunt.hunt_theme.boxes.color,
          }}
        >
          {props.teamOptions && props.teamOptions.length > 0 ? (
            <>
              <h5 style={{ marginTop: "0px", marginBottom: "5px" }}>
                {props.lang === "Hebrew"
                  ? "בחרו צוות אליו תצטרפו ותצברו נקודות יחד"
                  : "Join a team and play together"}
              </h5>
              <select
                id="selectTeam"
                value={selectedTeam}
                onChange={(e) => {
                  setSelectedTeam(e.target.value);
                }}
              >
                {props.teamOptions.map((item, index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </select>
              <div style={{ textAlign: "center" }}>
                <LoadingBtn
                  lang={props.lang}
                  style={{
                    width: "100%",
                    margin: "5px auto 0px auto",
                    backgroundColor: props.hunt.hunt_theme.header.bg,
                    color: props.hunt.hunt_theme.header.color,
                    borderColor: props.hunt.hunt_theme.header.bg,
                  }}
                  txt={
                    localization.find((l) => l.name === "props.enterGame")[
                      props.lang
                    ]
                  }
                  loading={props.submitLoading}
                  onClick={(e) => props.enterGame(e, "team", selectedTeam)}
                  type="submit"
                  customIcon={
                    props.lang === "Hebrew" && <FaArrowAltCircleLeft />
                  }
                />
                {props.loginError && (
                  <span className="error" style={{ fontSize: "12px" }}>
                    {props.loginError}
                  </span>
                )}
              </div>
            </>
          ) : (
            <h5 style={{ marginTop: "0px", marginBottom: "5px" }}>
              {localization.find((l) => l.name === "noTeams")[props.lang]}
            </h5>
          )}

          {/* <BasicSelect
            options={props.teamOptions}
            lang={props.lang}
            label={
              localization.find((l) => l.name === "chooseTeam")[props.lang]
            }
            placeholder={
              localization.find((l) => l.name === "chooseTeam")[props.lang]
            }
            value={selectedTeam}
            onChange={(e) => {
              setSelectedTeam(e.target.value, "team", null);
              props.enterGame(e);
            }}
          /> */}
        </div>
      ),
    },
    {
      value: "watcher",
      Hebrew: "צפה מהצד",
      English: "Watch the game",
      disabled: !props.hunt.has_watchers,
      icon: (
        <FaEye size={40} style={{ fill: props.hunt.hunt_theme.boxes.color }} />
      ),
      component: (
        <div
          className="iconBox"
          style={{
            margin: "10px auto",
            width: props.isTabletOrMobile ? "80%" : "50%",
            backgroundColor: props.hunt.hunt_theme.boxes.bg,
            border: "3px solid",
            borderColor: props.hunt.hunt_theme.boxes.color,
          }}
        >
          <MultiTextField
            lang={props.lang}
            label={
              localization.find((l) => l.name === "watcherName")[props.lang]
            }
            placeholder={
              localization.find((l) => l.name === "watcherName")[props.lang]
            }
            value={playerName}
            onChange={(e) => {
              props.setLoginError(null);
              setPlayerName(e.target.value);
            }}
          />
          <div style={{ textAlign: "center" }}>
            <LoadingBtn
              lang={props.lang}
              style={{
                width: "100%",
                margin: "10px auto 00px auto",
                backgroundColor: props.hunt.hunt_theme.header.bg,
                color: props.hunt.hunt_theme.header.color,
                borderColor: props.hunt.hunt_theme.header.bg,
              }}
              txt={
                localization.find((l) => l.name === "props.enterGame")[
                  props.lang
                ]
              }
              loading={props.submitLoading}
              onClick={(e) => props.enterGame(e, "watcher", playerName)}
              type="submit"
              customIcon={props.lang === "Hebrew" && <FaArrowAltCircleLeft />}
            />
            {props.loginError && (
              <span className="error" style={{ fontSize: "12px" }}>
                {props.loginError}
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div style={{ display: "block" }}>
      {types.map((type, index) =>
        !type.disabled ? (
          <FlipCard
            index={index}
            key={index}
            front={
              <div
                className="iconBox pointer"
                style={{
                  margin: props.isTabletOrMobile ? "5px auto" : "5px auto",
                  width: props.isTabletOrMobile ? "80%" : "50%",
                  backgroundColor: props.hunt.hunt_theme.boxes.bg,
                  // padding: props.isTabletOrMobile && "2rem",
                }}
              >
                <h2 style={{ marginTop: "10px" }}>{type.icon}</h2>
                <h1
                  style={{
                    color: props.hunt.hunt_theme.boxes.color,
                    marginTop: "0px",
                    fontSize: "26px",
                  }}
                >
                  {type[props.lang]}
                </h1>
              </div>
            }
            back={type.component}
          />
        ) : (
          <div
            key={index}
            className="flip-card"
            style={{ height: index > 0 ? "160px" : "175px" }}
          >
            <div className="flip-card-inner-disabled">
              <div className="flip-card-front">
                <div
                  className="iconBox crossed"
                  style={{
                    margin: props.isTabletOrMobile ? "5px auto" : "5px auto",
                    width: props.isTabletOrMobile ? "80%" : "50%",
                    backgroundColor: props.hunt.hunt_theme.boxes.bg,
                    opacity: "0.5",
                    // padding: props.isTabletOrMobile && "2rem",
                  }}
                >
                  <h2 style={{ marginTop: "10px" }}>{type.icon}</h2>
                  <h1
                    style={{
                      color: props.hunt.hunt_theme.boxes.color,
                      marginTop: "0px",
                    }}
                  >
                    {type[props.lang]}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
