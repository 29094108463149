/* eslint-disable */

import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import CampaignIcon from "@mui/icons-material/Campaign";
import LogoutIcon from "@mui/icons-material/Logout";

export default function Header(props) {
  const [logoSize, setLogoSize] = useState({});

  useEffect(() => {
    // const logoImg = document.getElementById("gameLogo");
    // let width = logoImg.width;
    // let height = logoImg.height;
    // if (width > height) {
    //   setLogoSize({
    //     width: width,
    //     height: height,
    //   });
    // } else if (height > width) {
    //   setLogoSize({
    //     width: width,
    //     height: height,
    //   });
    // } else if (width === height) {
    //   setLogoSize({
    //     width: "50px",
    //     height: "50px",
    //   });
    // }
  }, []);

  return (
    <div
      id="gameHeader"
      style={{
        justifyContent: props.player ? "space-between" : "center",
        backgroundColor: props.theme.header.bg,
        // borderBottom: "2px solid",
        borderColor: props.theme.header.color,
        direction: "rtl",
      }}
    >
      {props.player && (
        <div
          style={{
            // backgroundColor: props.theme.header.color,
            justifyContent: props.lang === "Hebrew" ? "start" : "end",
            alignItems: "center",
            display: "flex",
            borderRadius:
              props.lang === "Hebrew"
                ? "0px 50px 50px 0px"
                : "50px 0px 0px 50px",
            width: "20%",
          }}
          onClick={() => {
            props.logOut();

            // let warning =
            //   props.player.type === "individual"
            //     ? "You will lose your score if you log out, are you sure you want to log out?"
            //     : "Are you sure you want to log out?";
            // let confirmed = window.confirm(warning);
            // if (confirmed) {
            //   props.logOut();
            // }
          }}
        >
          <IconButton size="large" aria-label="notifications" color="inherit">
            <LogoutIcon
              sx={{ fill: props.theme.header.color, fontSize: "32px" }}
            />
          </IconButton>
        </div>
      )}
      <div
        style={{
          textAlign: !props.player && "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: !props.player && "15px",
          width: "60%",
        }}
        onClick={() =>
          props.hunt.logo_link && window.open(props.hunt.logo_link, "_blank")
        }
      >
        <img src={props.logo} id="gameLogo" style={{ width: "140px" }} />
      </div>
      {props.player && props.player.type != "watcher" && (
        <div
          style={{
            width: "20%",
            // backgroundColor: props.theme.header.color,
            justifyContent: props.lang === "Hebrew" ? "end" : "start",
            alignItems: "center",
            display: "flex",
            borderRadius:
              props.lang === "Hebrew"
                ? "0px 50px 50px 0px"
                : "50px 0px 0px 50px",
          }}
          onClick={() => props.setActiveTab(props.pages.length - 1)}
        >
          {props.status === "Active" && (
            <IconButton size="large" aria-label="notifications" color="inherit">
              <Badge badgeContent={props.notifications} color="error">
                <CampaignIcon
                  sx={{ fill: props.theme.header.color, fontSize: "32px" }}
                />
              </Badge>
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
}
