/* eslint-disable */

import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";

const filter = createFilterOptions();

export default function UseAutocomplete(props) {
  const [value, setValue] = React.useState(null);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        // props.onChange(newValue)
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        if (inputValue != "" && inputValue != props.query) {
          props.onChange(inputValue);
        }
        // Suggest the creation of a new value
        // const isExisting = options.some(
        //   (option) => inputValue === option.title
        // );
        // if (inputValue !== "" && !isExisting) {
        //     filtered.push({
        //       inputValue,
        //       title: `Add "${inputValue}"`,
        //     });
        // }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={props.id}
      options={props.options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => (
        <li {...props}>
          {option.title} - {option.category ? option.category : "UNCATEGORIZED"}
        </li>
      )}
      sx={{ width: props.width }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: value && (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={() => props.submit(value)}
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={() => {
                    props.clear();
                    setValue(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
