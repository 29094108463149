/* eslint-disable */

import { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL, ADMIN_API, UPLOADS_API } from "../../Config";
import Loader from "../partials/Loader";
import StandardImageList from "../mui/MediaGallery";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LoadingBtn from "../mui/LoadingBtn";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import CreateApiClient from "../../API";

const api = CreateApiClient();

export default function HuntMedia(props) {
  const [media, setMedia] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState("photo");
  const [exportLoading, setExportLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [deleteSelectedLoading, setDeleteSelectedLoading] = useState(false);

  useEffect(() => {
    getMedia();
  }, [mediaType]);

  const onAddMediaSelected = (media) => {
    selectedMedia.includes(media)
      ? setSelectedMedia(selectedMedia.filter((s) => s != media))
      : setSelectedMedia([...selectedMedia, media]);
  };

  const getMedia = async (noLoading) => {
    setPage(page);
    if (page === 0 && !noLoading) {
      setLoading(true);
    }
    const url = `${SERVER_URL}/${ADMIN_API}/media?id=${props.hunt.hunt._id}&type=${mediaType}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    setMedia(data);
    setPage(page);
    if (page === 0) {
      setLoading(false);
    }
  };

  const deleteMedia = async () => {
    setDeleteLoading(true);
    const url = `${SERVER_URL}/${UPLOADS_API}/delete-files?id=${props.hunt.hunt._id}&type=${mediaType}`;
    const headers = { "auth-token": props.user.token };

    const res = await api.downloadMedia("POST", url, headers, null);
    getMedia(true);
    setDeleteLoading(false);
  };

  const deleteSelectedMedia = async () => {
    setDeleteSelectedLoading(true);
    const url = `${SERVER_URL}/${UPLOADS_API}/delete-files?id=${props.hunt.hunt._id}&type=${mediaType}`;
    const headers = { "auth-token": props.user.token };
    const body = { media: selectedMedia };

    const res = await api.downloadMedia("POST", url, headers, body);
    getMedia(true);
    setDeleteSelectedLoading(false);
  };

  // const downloadMedia = async () => {
  //   setExportLoading(true);
  //   const url = `${SERVER_URL}/${UPLOADS_API}/download-files?id=${props.hunt.hunt._id}&type=${mediaType}`;
  //   const headers = { "auth-token": props.user.token };

  //   const res = await api.downloadMedia("POST", url, headers, null);

  //   if (res.data === "OK") {
  //     const _res = await axios
  //       .post(
  //         `${SERVER_URL}/${UPLOADS_API}/exports?id=${props.hunt.hunt._id}`,
  //         {},
  //         { headers: headers, responseType: "arraybuffer" }
  //       )
  //       .then((response) => response)
  //       .catch((error) => error.response);
  //     const disposition = _res.request.getResponseHeader("Content-Disposition");
  //     var fileName = "";
  //     var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //     var matches = filenameRegex.exec(disposition);
  //     if (matches != null && matches[1]) {
  //       fileName = matches[1].replace(/['"]/g, "");
  //     }

  //     let blob = new Blob([_res.data], { type: "application/zip" });
  //     const downloadUrl = URL.createObjectURL(blob);
  //     let a = document.createElement("a");
  //     a.href = downloadUrl;
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.click();
  //   }
  //   setExportLoading(false);
  // };

  const downloadMedia = async () => {
    setExportLoading(true);
    const url = `${SERVER_URL}/${UPLOADS_API}/download-files?id=${props.hunt.hunt._id}&type=${mediaType}`;
    const headers = { "auth-token": props.user.token };

    const res = await api.downloadMedia("POST", url, headers, null);

    if (res.data === "Processing") {
      const checkFileReady = async () => {
        const _res = await axios
          .post(
            `${SERVER_URL}/${UPLOADS_API}/exports?id=${props.hunt.hunt._id}`,
            {},
            { headers: headers, responseType: "arraybuffer" }
          )
          .then((response) => response)
          .catch((error) => error.response);

        if (_res.status === 200) {
          const disposition = _res.request.getResponseHeader(
            "Content-Disposition"
          );
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }

          let blob = new Blob([_res.data], { type: "application/zip" });
          const downloadUrl = URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          setExportLoading(false);
        } else if (_res.status === 202) {
          setTimeout(checkFileReady, 1000); // Check again after 1 second
        } else {
          setExportLoading(false);
        }
      };

      checkFileReady();
    } else {
      setExportLoading(false);
    }
  };

  const handleChange = (event, newType) => {
    setMediaType(newType);
  };

  return (
    <div className="english inpageSection">
      <h1>Media Gallery</h1>
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={mediaType}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="photo">Images</ToggleButton>
          <ToggleButton value="video">Videos</ToggleButton>
        </ToggleButtonGroup>
        {media.length > 0 && (
          <div
            style={{
              display: !props.isTabletOrMobile ? "flex" : "block",
              justifyContent: !props.isTabletOrMobile && "center",
              alignItems: !props.isTabletOrMobile && "center",
              textAlign: props.isTabletOrMobile && "right",
            }}
          >
            <LoadingBtn
              style={{
                minWidth: props.isTabletOrMobile && "150px",
                margin: props.isTabletOrMobile ? "2px 0px" : "0px 2px",
                backgroundColor: "#fabe44",
                color: "black",
                borderColor: "#fabe44",
              }}
              customIcon={<DownloadIcon />}
              txt="Download"
              loading={exportLoading}
              onClick={downloadMedia}
              type="submit"
            />
            <LoadingBtn
              style={{
                minWidth: props.isTabletOrMobile && "150px",
                margin: props.isTabletOrMobile ? "2px 0px" : "0px 2px",
                backgroundColor: "#fabe44",
                color: "black",
                borderColor: "#fabe44",
              }}
              customIcon={<DeleteIcon />}
              txt="Delete Selected"
              loading={deleteSelectedLoading}
              onClick={() => {
                let confirmed = window.confirm(
                  `Are you sure you want to delete media?`
                );
                if (confirmed) {
                  deleteSelectedMedia();
                }
              }}
              type="submit"
            />
            <LoadingBtn
              style={{
                minWidth: props.isTabletOrMobile && "150px",
                margin: props.isTabletOrMobile ? "2px 0px" : "0px 2px",
                backgroundColor: "#fabe44",
                color: "black",
                borderColor: "#fabe44",
              }}
              customIcon={<DeleteIcon />}
              txt="Delete All"
              loading={deleteLoading}
              onClick={() => {
                let confirmed = window.confirm(
                  `Are you sure you want to delete media?`
                );
                if (confirmed) {
                  deleteMedia();
                }
              }}
              type="submit"
            />
          </div>
        )}
      </div>

      <hr />
      {loading ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {media.length > 0 ? (
            <StandardImageList
              selectedMedia={selectedMedia}
              onAddMediaSelected={(media) => onAddMediaSelected(media)}
              type={mediaType}
              media={media}
              user={props.user}
              hunt={props.hunt}
              isTabletOrMobile={props.isTabletOrMobile}
            />
          ) : (
            <div
              style={{
                height: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>No media yet</h2>
            </div>
          )}
        </>
      )}
    </div>
  );
}
