/* eslint-disable */

import { useEffect, useState } from "react";
import Header from "./Header";
import { SERVER_URL, GAME_API, GC_URL } from "../../Config";
import Loader from "../partials/Loader";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import FixedBottomNavigation from "../mui/BottomNavigation";
import Welcome from "./Welcome";
import { checkTiming } from "../../hooks/checkTiming";
import { FaUserNinja, FaUsers, FaUser, FaEye, FaStar } from "react-icons/fa";
import { formatDate } from "../../hooks/formatDate";
import TaskList from "./TaskList";
import Submissions from "./Submissions";
import { io } from "socket.io-client";
import Notification from "../partials/Notification";
import { verifyPlayer } from "../../hooks/verifyPlayer";
import CLG_LOGO from "../../assets/images/clg_logo_square.png";
import ConfettiExplosion from "react-confetti-explosion";
import goldIcon from "../../assets/icons/goldIcon.png";
import silverIcon from "../../assets/icons/silverIcon.png";
import bronzeIcon from "../../assets/icons/bronzeIcon.png";
import Countdown from "react-countdown";
import CreateApiClient from "../../API";

const api = CreateApiClient();

const mediaTypes = ["photo", "video"];

export default function Game(props) {
  const [lang, setLang] = useState("");
  const [loading, setLoading] = useState(false);
  const [hunt, setHunt] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [player, setPlayer] = useState(props.player);
  const [status, setStatus] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [socialBonusLoading, setSocialBonusLoading] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loginError, setLoginError] = useState(null);
  const [socialBonusValue, setSocialBonusValue] = useState(0);
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const [socket, setSocket] = useState(null);
  const [notification, setNotification] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [alertsCount, setAlertsCount] = useState(0);
  const [isExploding, setIsExploding] = useState(null);
  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [categories, setCategories] = useState([]);
  const [socialScores, setSocialScores] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaPage, setMediaPage] = useState(0);
  const [hasMoreMedia, setHasMoreMedia] = useState(true);

  useEffect(() => {
    setIsExploding(null);
    const id = location.pathname.split("/")[2];
    setSocket(io(SERVER_URL));
    getHunt(id, null, activeTab);
  }, [activeTab, location.pathname, mediaPage]);

  useEffect(() => {
    setMediaPage(0);
  }, [activeTab]);

  useEffect(() => {
    if (hunt && player) {
      socket?.emit("newUser", { player: player, hunt: hunt });
      const playerCompletions = hunt.submissions?.filter(
        (s) => s.name === player.name
      ).length;
      if (playerCompletions === tasks.length) {
        setCompleted(true);
      }
    }
  }, [socket, hunt, player]);

  useEffect(() => {
    socket?.on("notify", (data) => {
      let totalAlerts = 0;
      let seen = localStorage.getItem("seen");
      seen = Number(seen);
      if (seen > 0) {
        totalAlerts += seen + 1;
      }
      setNotification(data);
      setWelcomeMsg(data);
      setTimeout(() => {
        const id = location.pathname.split("/")[2];
        getHunt(id, true);
        setNotification(null);
      }, 5000);
    });
  }, [socket]);

  const loadMoreMedia = () => {
    setMediaPage(mediaPage + 1);
  };

  const getHunt = async (id, inPageLoading, tab) => {
    if (!inPageLoading && mediaPage < 1) {
      setLoading(true);
    }

    setMediaLoading(true);
    let _player;
    if (!player) {
      _player = await checkPlayer(id);
    }

    let withSubmissions = null;
    if (tab === 1) {
      withSubmissions = "filtered";
    } else if (tab === 2) {
      withSubmissions = "all";
    }

    const url = `${SERVER_URL}/${GAME_API}/hunt?id=${id}&name=${
      player ? player.name : _player ? _player.name : null
    }&submissions=${withSubmissions}&type=${
      player ? player.type : _player ? _player.type : null
    }&page=${mediaPage}`;

    const data = await api.fetchData("GET", url, {}, null);

    // Create a map of existing submissions by ID
    const existingSubmissionsMap = new Map(submissions.map((s) => [s._id, s]));

    // Merge new submissions and filter out duplicates
    const uniqueSubmissions = [
      ...existingSubmissionsMap.values(),
      ...data.hunt.submissions?.filter(
        (s) => mediaTypes.includes(s.type) && !existingSubmissionsMap.has(s._id)
      ),
    ];

    setSubmissions(uniqueSubmissions);

    setHunt(data.hunt);
    let sortedTasks = data.tasks.sort(
      (a, b) =>
        a.hunts.find((h) => h.id.toString() === id.toString()).position -
        b.hunts.find((h) => h.id.toString() === id.toString()).position
    );

    // let _categories = [...new Set(sortedTasks.map((task) => task.category))];
    setCategories(data.categories);
    setTasks(sortedTasks);
    setLang(data.hunt.language);
    setSocialScores(data.hunt.social_scores);

    setHasMoreMedia(data.hasMore);
    const langAttr = data.hunt.language.substr(0, 2).toLowerCase();
    const divs = document.querySelectorAll("div");

    divs.forEach((div, index) => {
      div.setAttribute("lang", langAttr);
    });

    setTeamOptions(data.teamOptions);
    let seen = localStorage.getItem("seen");
    seen = Number(seen);
    if (data.hunt.notifications.length > 0) {
      !welcomeMsg &&
        setWelcomeMsg(
          data.hunt.notifications[data.hunt.notifications.length - 1].msg
        );
      if (seen > 0) {
        setAlertsCount(data.hunt.notifications.length - seen);
      } else {
        setAlertsCount(data.hunt.notifications.length);
      }
    } else {
      setAlertsCount(data.hunt.notifications.length);
    }

    if (activeTab === 5) {
      localStorage.setItem("seen", data.hunt.notifications.length);
    }

    document.dir = data.hunt.language === "Hebrew" ? "rtl" : "ltr";

    setStatus(checkTiming(data.hunt));

    setMediaLoading(false);
    if (!inPageLoading) {
      setLoading(false);
    }
  };

  const newPlayer = (player) => {
    setPlayer(player);
    let _player = JSON.stringify(player);
    localStorage.setItem("player", _player);
  };

  const checkPlayer = async (id) => {
    let _player = localStorage.getItem("player");
    if (_player) {
      _player = JSON.parse(_player);
      const verified = await verifyPlayer(id, _player.name);
      if (verified) {
        setPlayer(_player);
      } else {
        // localStorage.removeItem("player");
        setPlayer(null);
      }
    } else {
      // localStorage.removeItem("player");
      setPlayer(null);
    }
    return _player;
  };

  const enterGame = async (e, type, playerName) => {
    setSubmitLoading(true);
    let obj = {
      type: type,
      name: playerName,
    };

    const url = `${SERVER_URL}/${GAME_API}/enter-game?id=${hunt._id}`;

    const data = await api.updateData("POST", url, {}, obj);
    if (data.name) {
      newPlayer(data);
      setHunt(data.hunt);
      setAlertsCount(0);
      localStorage.setItem("seen", 0);
    } else {
      setLoginError(data);
    }
    setSubmitLoading(false);
  };

  const addScore = async (to, from, value) => {
    // setIsExploding(null);
    let obj = {
      to: to._id,
      from: from._id,
      value: value,
    };
    setIsExploding(to._id);
    setSocialScores([...socialScores, obj]);
    // setSocialBonusLoading(to._id);
    // setSocialBonusValue(value);
    const url = `${SERVER_URL}/${GAME_API}/social-score?id=${hunt._id}&to=${to._id}&from=${from._id}&value=${value}&name=${to.name}`;
    const data = await api.updateData("POST", url, {}, {});
    setTimeout(() => {
      setIsExploding(null);
    }, 2000);
    // getHunt(hunt._id, true);
    // setSocialBonusValue(0);
    // setSocialBonusLoading(null);
  };

  const logOut = async () => {
    let warning =
      player.type === "individual"
        ? "You will lose your score if you log out, are you sure you want to log out?"
        : "Are you sure you want to log out?";
    let confirmed = window.confirm(warning);
    if (confirmed) {
      if (player.type === "team") {
        const url = `${SERVER_URL}/${GAME_API}/log-out?name=${player.name}&type=${player.type}&id=${hunt._id}`;
        const data = await api.updateData("POST", url, {}, {});
        if (data === "OK") {
          localStorage.removeItem("player");
          window.location.reload();
        }
      } else {
        localStorage.removeItem("player");
        window.location.reload();
      }
    }
  };

  const localization = [
    {
      name: "companyNamePrefix",
      Hebrew: "על ידי",
      English: "By",
    },
    {
      name: "score",
      Hebrew: "נקודות",
      English: "Points",
    },
    {
      name: "endDatePrefix",
      Hebrew: "סיום: ",
      English: "Ends",
    },
    {
      name: "startDatePrefix",
      Hebrew: "מתחיל ב",
      English: "Starts",
    },
    // {
    //   name: "endDate",
    //   Hebrew: (date) => {
    //     let day = date.getDay();
    //   },
    // },
    {
      name: "tasksTitle",
      Hebrew: "משימות",
      English: "Challenges",
    },
    {
      name: "noPosts",
      Hebrew: "לא הועלו פוסטים בשלב זה",
      English: "No Posts Yet",
    },
    {
      name: "noTasks",
      Hebrew: "לא הועלו משימות בשלב זה",
      English: "No Tasks Yet",
    },
    {
      name: "guideTitle",
      Hebrew: "הוראות המשחק",
      English: "Game Rules",
    },
    {
      name: "boardTitle",
      Hebrew: "לוח מובילים",
      English: "Leaderboard",
    },
    {
      name: "feedTitle",
      Hebrew: "Feed",
      English: "Feed",
    },
    {
      name: "notificationsTitle",
      Hebrew: "התראות",
      English: "Notifications",
    },
    {
      name: "completedTitle",
      Hebrew: "השלמתם את כל המשימות!",
      English: "You've completed all tasks!",
    },
    {
      name: "noLeaderboard",
      Hebrew: "לוח התוצאות ייחשף בסוף המשחק",
      English: "Leaderboard will be visible at the end of the game",
    },
    {
      name: "huntOver",
      Hebrew: "המשחק נגמר!",
      English: "Hunt is over!",
    },
    {
      name: "rulesTitle",
      Hebrew: "חוקי המשחק:",
      English: "Game Rules:",
    },
  ];

  function NewlineText(text) {
    const newText = text.split("\r\n").map((str, index) => (
      <p key={index} style={{ color: hunt.hunt_theme.body.color }}>
        {str}
      </p>
    ));

    return newText;
  }

  const winnersIcons = [goldIcon, silverIcon, bronzeIcon];
  const pages = [
    {
      name: "home",
      component: hunt && player && (
        <div style={{ minHeight: "100vh" }}>
          <div
            style={{
              width: "100%",
              height: hunt.welcome_banner ? "30vh" : "15vh",
              background: `url(${GC_URL}/${hunt.welcome_banner.replace(
                " ",
                "%20"
              )})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              // borderBottom: "2px solid",
              borderColor: hunt.hunt_theme.header.bg,
            }}
            onClick={() =>
              hunt.welcome_link && window.open(hunt.welcome_link, "_blank")
            }
          ></div>
          <div
            style={{ textAlign: "center" }}
            onClick={() =>
              hunt.square_logo_link &&
              window.open(hunt.square_logo_link, "_blank")
            }
          >
            <img
              alt={hunt.hunt_name}
              src={hunt.company_square_logo}
              style={{
                zIndex: 9,
                width: "100px",
                height: "100px",
                borderRadius: "80px",
                // border: "2px solid",
                borderColor: hunt.hunt_theme.header.color,
                // padding: "15px",
                marginTop: "-60px",
                backgroundColor: hunt.hunt_theme.header.bg,
              }}
            />
          </div>
          <div className="gameHome" lang={lang.substr(0, 2).toLowerCase()}>
            <h2
              // lang={lang.substr(0, 2).toLowerCase()}
              style={{
                margin: "5px auto 15px",
                color: hunt.hunt_theme.body.color,
                // fontFamily: lang === "English" ? "Rajdhani" : "Assistant",
              }}
            >
              {hunt.hunt_name}
            </h2>
            {/* <h5 style={{ margin: "0px" }}>
              {localization.find((l) => l.name === "companyNamePrefix")[lang]}{" "}
              {hunt.company_name}
            </h5> */}
            {/* <h5 style={{ margin: "0px", color: hunt.hunt_theme.body.color }}>
              {hunt.welcome_message}
            </h5> */}
            {!hunt.has_end_date && <br />}
            {hunt.has_end_date && (
              <div
                style={{
                  color: hunt.hunt_theme.boxes.color,
                  backgroundColor: hunt.hunt_theme.boxes.bg,
                  borderRadius: "5px",
                  width: status === "Active" ? "40%" : "80%",
                  // minWidth: "50%",
                  // maxWidth: "70%",
                  margin: "0px auto",
                }}
              >
                {status === "Active" ? (
                  <div
                    style={{
                      fontWeight: "bold",
                      padding: "6px",
                      fontSize: "18px",
                      // color: hunt.hunt_theme.body.color,
                    }}
                  >
                    {/* <h4 style={{ padding: "6px", margin: "0px 0px 4px" }}>
                    {localization.find((l) => l.name === "endDatePrefix")[lang]}{" "}
                    {formatDate(new Date(hunt.end_date))}{" "}
                    {hunt.timeZone && hunt.timeZone}
                  </h4> */}
                    <Countdown
                      date={new Date(hunt.end_date)}
                      onComplete={() => window.location.reload()}
                    />
                  </div>
                ) : status === "Completed" ? (
                  <h4 style={{ padding: "3px", margin: "0px 0px 4px" }}>
                    {localization.find((l) => l.name === "huntOver")[lang]}{" "}
                  </h4>
                ) : (
                  <h4 style={{ padding: "3px", margin: "0px 0px 4px" }}>
                    {
                      localization.find((l) => l.name === "startDatePrefix")[
                        lang
                      ]
                    }{" "}
                    {formatDate(new Date(hunt.start_date))}
                    <br />
                    {hunt.timeZone && hunt.timeZone}
                  </h4>
                )}
              </div>
            )}
            {hunt.notifications && hunt.notifications.length > 0 && (
              <h3
                style={{
                  margin: "15px auto",
                  padding: "5px",
                  color: hunt.hunt_theme.body.color,
                }}
              >
                {welcomeMsg}
              </h3>
            )}
            <br />
            {status != "Completed" ? (
              <div
                className="iconBox"
                style={{
                  margin: "auto",
                  padding: "5px",
                  color: hunt.hunt_theme.boxes.color,
                  backgroundColor: hunt.hunt_theme.boxes.bg,
                  display: "inline-flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  minWidth: "50%",
                  maxWidth: "90%",
                  // width: "50%",
                  margin: "auto",
                  borderRadius: "5px",
                }}
              >
                {/* <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                > */}
                <div style={{ paddingTop: "5px" }}>
                  {player.type === "team" ? (
                    <FaUsers size={25} />
                  ) : player.type === "watcher" ? (
                    <FaEye size={25} />
                  ) : (
                    <FaUserNinja size={25} />
                  )}
                </div>
                <div>
                  <h4 style={{ margin: "0px 8px", whiteSpace: "break-spaces" }}>
                    {player.name}
                  </h4>
                </div>
                {/* </div> */}
                {player.type !== "watcher" && (
                  <>
                    <div
                      className="scoreBox"
                      style={{
                        margin: "2px 0px",
                        color: hunt.hunt_theme.header.color,
                        backgroundColor: hunt.hunt_theme.header.bg,
                        width: "80px",
                        height: "30px",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {
                        hunt.players.find(
                          (p) =>
                            p.name === player.name && p.type === player.type
                        )?.score
                      }
                    </div>
                    {/* <h2>
                      {localization.find((l) => l.name === "score")[lang]}
                    </h2> */}
                  </>
                )}
              </div>
            ) : (
              hunt &&
              player &&
              hunt.players.length > 0 &&
              hunt.visible_leaderboard && (
                <>
                  <div
                    className="gameHome"
                    style={
                      {
                        // background: `url(${GC_URL}/${hunt.bg_img})`,
                        // backgroundColor: hunt.hunt_theme.body.bg,
                      }
                    }
                  >
                    <div
                      style={{
                        padding: "10px",
                        paddingBottom: "1rem",
                        // minHeight: "100vh",
                        // overflowY: "scroll",
                      }}
                    >
                      <br />
                      <FaUsers
                        size={35}
                        style={{ fill: hunt.hunt_theme.body.color }}
                      />
                      <h2
                        style={{
                          margin: "0px",
                          color: hunt.hunt_theme.body.color,
                        }}
                      >
                        {
                          localization.find((l) => l.name === "boardTitle")[
                            lang
                          ]
                        }
                      </h2>
                      <br />
                      {hunt && hunt.visible_leaderboard ? (
                        <>
                          {hunt.players
                            .filter((p) => p.type !== "watcher")
                            .sort((a, b) => b.score - a.score)
                            .map((player, index) => (
                              <div
                                id="scoreDiv"
                                key={index}
                                style={{
                                  backgroundColor: hunt.hunt_theme.boxes.bg,
                                  color: hunt.hunt_theme.boxes.color,
                                  borderRadius: "50px",
                                  padding: index < 3 ? "4px 25px" : "2px 15px",
                                  // height: index < 3 ? "60px" : "30px",
                                }}
                              >
                                <div
                                  style={{
                                    color: hunt.hunt_theme.header.color,
                                    backgroundColor: hunt.hunt_theme.header.bg,
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <h3>{index + 1}</h3>
                                </div>
                                <div>
                                  <h3>{player.name}</h3>
                                </div>
                                <div
                                  style={{
                                    color: hunt.hunt_theme.header.color,
                                    backgroundColor: hunt.hunt_theme.header.bg,
                                    width: "80px",
                                    height: "40px",
                                    borderRadius: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <h3>{player.score ? player.score : 0}</h3>
                                </div>
                              </div>
                            ))}
                        </>
                      ) : (
                        hunt && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minHeight: "80vh",
                            }}
                          >
                            <h2 style={{ color: hunt.hunt_theme.body.color }}>
                              {
                                localization.find(
                                  (l) => l.name === "noLeaderboard"
                                )[lang]
                              }
                            </h2>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              )
            )}
            {status != "Completed" && (
              <div style={{ padding: "2rem" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    color: hunt.hunt_theme.body.color,
                  }}
                >
                  {localization.find((l) => l.name === "rulesTitle")[lang]}
                </h3>
                {NewlineText(hunt.instructions)}
              </div>
            )}
          </div>
        </div>
      ),
    },
    // {
    //   name: "guide",
    //   component: hunt && player && hunt.instructions.length > 0 && (
    //     <>
    //       <div
    //         className="gameHome"
    //         style={{
    //           // background: `url(${GC_URL}/${hunt.bg_img.replace(/ /g, "%20")})`,
    //           // backgroundSize: "contain",
    //           // backgroundRepeat: "no-repeat",

    //           // backgroundColor: hunt.hunt_theme.body.bg,
    //           padding: "1rem 0.5rem",
    //         }}
    //       >
    //         <fieldset
    //           style={{
    //             padding: "10px",
    //             paddingBottom: "8rem",
    //             minHeight: "100vh",
    //             border: `1px solid ${hunt.hunt_theme.body.color}`,
    //             // height: "80vh",
    //             // overflowY: "scroll",
    //           }}
    //         >
    //           <br />
    //           <legend className="inline-center" style={{ width: "60%" }}>
    //             <FaRoute
    //               size={25}
    //               style={{ fill: hunt.hunt_theme.body.color }}
    //             />
    //             <h2
    //               style={{
    //                 margin: "0px 8px",
    //                 color: hunt.hunt_theme.body.color,
    //               }}
    //             >
    //               {localization.find((l) => l.name === "guideTitle")[lang]}
    //             </h2>
    //           </legend>
    //           {NewlineText(hunt.instructions)}
    //           {/* <p style={{ color: hunt.hunt_theme.body.color }}>
    //             {hunt.instructions.replace("\r\n", "\n")}
    //           </p> */}
    //         </fieldset>
    //       </div>
    //     </>
    //   ),
    // },
    {
      name: "tasks",
      component:
        hunt && player && tasks && tasks.length > 0 ? (
          <>
            <div
              className="gameHome"
              style={{
                // background: `url(${GC_URL}/${hunt.bg_img.replace(
                //   / /g,
                //   "%20"
                // )})`,
                // backgroundColor: hunt.hunt_theme.body.bg,
                // backgroundSize: "contain",
                // backgroundRepeat: "no-repeat",
                padding: "1rem 0.5rem",
              }}
            >
              <fieldset
                style={{
                  borderRadius: "10px",
                  padding: "10px",
                  paddingBottom: "1rem",
                  minHeight: "100vh",
                  border: `1px solid ${hunt.hunt_theme.body.color}`,
                  // height: "80vh",
                  // overflowY: "scroll",
                }}
              >
                <br />
                <legend className="inline-center" style={{ width: "40%" }}>
                  {/* <FaListUl
                    size={25}
                    style={{ fill: hunt.hunt_theme.body.color }}
                  /> */}
                  <h2
                    style={{
                      margin: "0px 8px",
                      color: hunt.hunt_theme.body.color,
                    }}
                  >
                    {localization.find((l) => l.name === "tasksTitle")[lang]}
                  </h2>
                </legend>
                {/* <br /> */}
                {completed ? (
                  <div>
                    <h3 style={{ color: hunt.hunt_theme.body.color }}>
                      {
                        localization.find((l) => l.name === "completedTitle")[
                          lang
                        ]
                      }
                    </h3>
                    <img
                      src={`${GC_URL}/${hunt.ending_banner}`}
                      style={{ borderRadius: "10px", width: "90%" }}
                    />
                  </div>
                ) : (
                  <TaskList
                    categories={categories}
                    isDesktopOrTablet={isDesktopOrTablet}
                    isTabletOrMobile={isTabletOrMobile}
                    tasks={tasks}
                    hunt={hunt}
                    lang={lang}
                    player={player}
                    getHunt={(id) => {
                      getHunt(id);
                    }}
                  />
                )}
              </fieldset>
            </div>
          </>
        ) : (
          hunt && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
              }}
            >
              <h2 style={{ color: hunt.hunt_theme.body.color }}>
                {localization.find((l) => l.name === "noTasks")[lang]}
              </h2>
            </div>
          )
        ),
    },
    {
      name: "submissions",
      component:
        hunt &&
        tasks &&
        player &&
        hunt.submissions &&
        hunt.submissions.filter((s) => mediaTypes.includes(s.type)).length >
          0 ? (
          <>
            <div
              className="gameHome"
              style={{
                padding: "15px",
                paddingBottom: "1rem",
                minHeight: "100vh",
              }}
            >
              {/* <div className="inline-center">
                <FaPhotoVideo
                  size={25}
                  style={{ fill: hunt.hunt_theme.body.color }}
                />
                <h2
                  style={{
                    margin: "0px 8px",
                    color: hunt.hunt_theme.body.color,
                  }}
                >
                  {localization.find((l) => l.name === "feedTitle")[lang]}
                </h2>
              </div> */}
              {/* {isExploding && (
                <ConfettiExplosion
                  force={0.6}
                  duration={5000}
                  particleCount={200}
                  height={1600}
                  width={1600}
                />
              )} */}
              <Submissions
                hasMoreMedia={hasMoreMedia}
                socialScores={socialScores}
                isDesktopOrTablet={isDesktopOrTablet}
                isTabletOrMobile={isTabletOrMobile}
                isExploding={isExploding}
                confetti={
                  <ConfettiExplosion
                    force={0.6}
                    duration={5000}
                    particleCount={200}
                    height={1600}
                    width={1600}
                  />
                }
                player={player}
                lang={lang}
                tasks={tasks}
                hunt={hunt}
                data={submissions}
                addScore={(to, from, value) => addScore(to, from, value)}
                socialBonusLoading={socialBonusLoading}
                socialBonusValue={socialBonusValue}
                getHunt={(page) => getHunt(hunt._id, true, activeTab, page)}
                activeTab={activeTab}
                loadMore={loadMoreMedia}
                loading={mediaLoading}
              />
            </div>
          </>
        ) : (
          hunt && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <h2 style={{ color: hunt.hunt_theme.body.color }}>
                {localization.find((l) => l.name === "noPosts")[lang]}
              </h2>
            </div>
          )
        ),
    },
    {
      name: "leaderboard",
      component: hunt && player && hunt.players.length > 0 && (
        <>
          <div
            className="gameHome"
            style={{
              // background: `url(${GC_URL}/${hunt.bg_img.replace(/ /g, "%20")})`,
              // backgroundColor: hunt.hunt_theme.body.bg,
              // backgroundSize: "contain",
              // backgroundRepeat: "no-repeat",
              // overflowY: "scroll",
              // maxHeight: "100vh",
              padding: "1rem 0.5rem",
            }}
          >
            <fieldset
              style={{
                borderRadius: "10px",

                padding: "10px 0px 0px",
                // paddingBottom: "1rem",
                minHeight: "100vh",
                border: `1px solid ${hunt.hunt_theme.body.color}`,
                // overflowY: "scroll",
              }}
            >
              <br />
              <legend className="inline-center" style={{ width: "50%" }}>
                <h2
                  style={{
                    margin: "0px 8px",
                    color: hunt.hunt_theme.body.color,
                  }}
                >
                  {localization.find((l) => l.name === "boardTitle")[lang]}
                </h2>
              </legend>
              {hunt && hunt.visible_leaderboard ? (
                <>
                  <div
                    // className="shadowDiv"
                    style={{
                      direction: "ltr",
                      display: "inline-flex",
                      width: "100%",
                      margin: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      top: "-15px",
                      // borderRadius: "0px 0px 20px 20px",
                      // paddingBottom: "10px",
                    }}
                  >
                    {hunt.players[1] && (
                      <div
                        style={{
                          position: "relative",
                          right: "-10px",
                          top: "20px",
                        }}
                      >
                        <p
                          style={{
                            color: hunt.hunt_theme.boxes.bg,
                            margin: "5px 0px",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          2
                        </p>
                        <div
                          style={{
                            backgroundColor: hunt.hunt_theme.boxes.bg,
                            zIndex: "1",
                            padding: "15px",
                            borderRadius: "50px",
                            boxShadow: `0px 0px 2px 2px ${hunt.hunt_theme.boxes.color}`,
                          }}
                        >
                          {hunt.players
                            .filter((p) => p.type !== "watcher")
                            .sort((a, b) => b.score - a.score)[1].type ===
                          "team" ? (
                            <FaUsers
                              size={40}
                              style={{ fill: hunt.hunt_theme.boxes.color }}
                            />
                          ) : (
                            <FaUser
                              size={40}
                              style={{ fill: hunt.hunt_theme.boxes.color }}
                            />
                          )}
                        </div>
                        <p
                          style={{
                            color: hunt.hunt_theme.body.color,
                            fontWeight: "bold",
                            margin: "10px 0px 0px",
                          }}
                        >
                          {
                            hunt.players
                              .filter((p) => p.type !== "watcher")
                              .sort((a, b) => b.score - a.score)[1].name
                          }
                        </p>
                        {hunt.players
                          .filter((p) => p.type !== "watcher")
                          .sort((a, b) => b.score - a.score)[1] && (
                          <span
                            style={{
                              color: hunt.hunt_theme.body.color,
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            {
                              hunt.players
                                .filter((p) => p.type !== "watcher")
                                .sort((a, b) => b.score - a.score)[1].score
                            }
                          </span>
                        )}
                      </div>
                    )}
                    {hunt.players[0] && (
                      <div>
                        <div
                          style={{
                            display: "inline-grid",
                            marginBottom: "-5px",
                          }}
                          // color={hunt.hunt_theme.boxes.color}
                        >
                          <FaStar
                            size={45}
                            style={{
                              fill: hunt.hunt_theme.boxes.bg,
                              position: "relative",
                              bottom: "-18px",
                            }}
                          />
                          <span
                            style={{
                              color: hunt.hunt_theme.boxes.color,
                              fontWeight: "bold",
                              fontSize: "20px",
                              position: "relative",
                              top: "-18px",
                            }}
                          >
                            1
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor: hunt.hunt_theme.boxes.bg,
                            position: "relative",
                            zIndex: "9",
                            padding: "20px",
                            borderRadius: "50px",
                            boxShadow: `0px 0px 2px 2px ${hunt.hunt_theme.boxes.color}`,
                          }}
                        >
                          {hunt.players
                            .filter((p) => p.type !== "watcher")
                            .sort((a, b) => b.score - a.score)[0].type ===
                          "team" ? (
                            <FaUsers
                              size={50}
                              style={{ fill: hunt.hunt_theme.boxes.color }}
                            />
                          ) : (
                            <FaUser
                              size={50}
                              style={{ fill: hunt.hunt_theme.boxes.color }}
                            />
                          )}
                        </div>
                        <p
                          style={{
                            color: hunt.hunt_theme.body.color,
                            fontWeight: "bold",
                            margin: "10px 0px 0px",
                          }}
                        >
                          {
                            hunt.players
                              .filter((p) => p.type !== "watcher")
                              .sort((a, b) => b.score - a.score)[0].name
                          }
                        </p>
                        <span
                          style={{
                            color: hunt.hunt_theme.body.color,
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          {
                            hunt.players
                              .filter((p) => p.type !== "watcher")
                              .sort((a, b) => b.score - a.score)[0].score
                          }
                        </span>
                      </div>
                    )}
                    {hunt.players[2] && (
                      <div
                        style={{
                          position: "relative",
                          left: "-10px",
                          top: "20px",
                        }}
                      >
                        <p
                          style={{
                            color: hunt.hunt_theme.boxes.bg,
                            margin: "5px 0px",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          3
                        </p>
                        <div
                          style={{
                            position: "relative",
                            backgroundColor: hunt.hunt_theme.boxes.bg,
                            zIndex: "1",
                            padding: "15px",
                            borderRadius: "50px",
                            boxShadow: `0px 0px 2px 2px ${hunt.hunt_theme.boxes.color}`,
                          }}
                        >
                          {hunt.players
                            .filter((p) => p.type !== "watcher")
                            .sort((a, b) => b.score - a.score)[2].type ===
                          "team" ? (
                            <FaUsers
                              size={40}
                              style={{ fill: hunt.hunt_theme.boxes.color }}
                            />
                          ) : (
                            <FaUser
                              size={40}
                              style={{ fill: hunt.hunt_theme.boxes.color }}
                            />
                          )}
                        </div>
                        <p
                          style={{
                            color: hunt.hunt_theme.body.color,
                            fontWeight: "bold",
                            margin: "10px 0px 0px",
                          }}
                        >
                          {
                            hunt.players
                              .filter((p) => p.type !== "watcher")
                              .sort((a, b) => b.score - a.score)[2].name
                          }
                        </p>
                        <span
                          style={{
                            color: hunt.hunt_theme.body.color,
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          {
                            hunt.players
                              .filter((p) => p.type !== "watcher")
                              .sort((a, b) => b.score - a.score)[2].score
                          }
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      borderRadius: "20px 20px 0px 0px",
                      padding: "30px 0px",
                      backgroundColor: hunt.hunt_theme.body.bg,
                      width: "95%",
                      margin: "auto",
                    }}
                  >
                    {hunt.players
                      .filter((p) => p.type !== "watcher")
                      .sort((a, b) => b.score - a.score)
                      .slice(3)
                      .map((player, index) => (
                        <div
                          id="scoreDiv"
                          key={index}
                          style={{
                            backgroundColor: hunt.hunt_theme.boxes.bg,
                            color: hunt.hunt_theme.boxes.color,
                            borderRadius: "10px",
                            padding: "2px 15px",
                            // height: index < 3 ? "60px" : "30px",
                          }}
                        >
                          {/* <div
                            style={{
                              color: hunt.hunt_theme.header.color,
                              backgroundColor: hunt.hunt_theme.header.bg,
                              width: "40px",
                              height: "40px",
                              borderRadius: "60px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h3>{index + 4}</h3>

                            {index < 3 ? (
                            <img src={winnersIcons[index]} width="50" />
                          ) : (
                            <h3>{index + 1}</h3>
                          )}
                          </div> */}
                          <h3>{index + 4}</h3>

                          <div
                            style={{
                              padding: "5px",
                              backgroundColor: hunt.hunt_theme.header.bg,
                              width: "30px",
                              height: "30px",
                              borderRadius: "60px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {player.type === "team" ? (
                              <FaUsers
                                size={20}
                                style={{ fill: hunt.hunt_theme.boxes.bg }}
                              />
                            ) : (
                              <FaUser
                                size={20}
                                style={{ fill: hunt.hunt_theme.boxes.bg }}
                              />
                            )}
                          </div>

                          <div style={{ width: "40%" }}>
                            <h3>{player.name}</h3>
                          </div>
                          <div
                            style={{
                              color: hunt.hunt_theme.boxes.color,
                              // backgroundColor: hunt.hunt_theme.header.bg,
                              width: "80px",
                              height: "40px",
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h4>{player.score ? player.score : 0}</h4>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                hunt && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "80vh",
                    }}
                  >
                    <h2 style={{ color: hunt.hunt_theme.body.color }}>
                      {
                        localization.find((l) => l.name === "noLeaderboard")[
                          lang
                        ]
                      }
                    </h2>
                  </div>
                )
              )}
            </fieldset>
          </div>
        </>
      ),
    },
    {
      name: "notifications",
      component: hunt && player && hunt.players.length > 0 && (
        <>
          <div
            className="gameHome"
            style={{
              // background: `url(${GC_URL}/${hunt.bg_img})`,
              // backgroundColor: hunt.hunt_theme.body.bg,
              // backgroundSize: "contain",
              // backgroundRepeat: "no-repeat",
              padding: "1rem 0.5rem",
            }}
          >
            <fieldset
              style={{
                borderRadius: "10px",

                padding: "10px",
                paddingBottom: "1rem",
                minHeight: "100vh",
                border: `1px solid ${hunt.hunt_theme.body.color}`,

                // overflowY: "scroll",
              }}
            >
              <legend className="inline-center" style={{ width: "40%" }}>
                {/* <CampaignIcon
                  sx={{ fill: hunt.hunt_theme.body.color, fontSize: "32px" }}
                /> */}
                <h2
                  style={{
                    margin: "0px 2px",
                    color: hunt.hunt_theme.body.color,
                  }}
                >
                  {
                    localization.find((l) => l.name === "notificationsTitle")[
                      lang
                    ]
                  }
                </h2>
              </legend>
              {hunt.notifications.length > 0 && (
                <div style={{ width: "95%", margin: "auto" }}>
                  {hunt.notifications
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((item, index) => (
                      <div
                        className="alertDiv"
                        key={index}
                        style={{
                          // backgroundColor: hunt.hunt_theme.boxes.bg,
                          // borderRadius: "10px",
                          // padding: "0.5rem 1rem",
                          width: isTabletOrMobile ? "100%" : "100%",
                          // margin: "10px auto",
                          display: "inline-flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <img
                            src={CLG_LOGO}
                            style={{
                              width: "50px",
                              borderRadius: "50px",
                              margin: "0px 5px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            backgroundColor: hunt.hunt_theme.boxes.bg,
                            borderRadius: "10px",
                            padding: "0.5rem 1rem",
                            width: isTabletOrMobile ? "90%" : "90%",
                            margin: "10px auto",
                            border: `6px solid ${hunt.hunt_theme.boxes.bg}`,
                            boxShadow: `0px 0px 2px 2px ${hunt.hunt_theme.boxes.color} inset`,
                            // boxShadow: `0px 0px 0px 2px ${hunt.hunt_theme.boxes.color} inset`,

                            // outline: `2px ridge ${hunt.hunt_theme.boxes.color}`,
                            // outlineOffset: "-10px",
                            // display: "inline-flex",
                            // justifyContent: "start",
                            // alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              margin: "0px",
                              color: hunt.hunt_theme.boxes.color,
                              fontWeight: "bold",
                              textAlign: lang === "Hebrew" ? "right" : "left",
                            }}
                          >
                            {item.msg}
                          </p>
                          <p
                            style={{
                              margin: "0px",
                              color: hunt.hunt_theme.boxes.color,
                              textAlign: lang === "Hebrew" ? "right" : "left",
                            }}
                          >
                            {formatDate(new Date(item.date))}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </fieldset>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <>
          {notification && hunt && player && (
            <Notification theme={hunt.hunt_theme} msg={notification} />
          )}
          {hunt && player && (
            <Header
              logOut={logOut}
              hunt={hunt}
              notifications={alertsCount}
              logo={hunt.company_logo}
              theme={hunt.hunt_theme}
              player={player}
              status={status}
              lang={lang}
              pages={pages}
              setActiveTab={(event) => {
                setActiveTab(event);
              }}
              // logo={hunt.company_logo}
            />
          )}
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader lang={lang} />
          </div>
          {hunt && player && status === "Active" && (
            <FixedBottomNavigation
              player={player}
              status={status}
              lang={lang}
              hunt={hunt}
              activeTab={activeTab}
              setActiveTab={(event) => {
                setActiveTab(event);
              }}
            />
          )}
        </>
      ) : (
        hunt && (
          <>
            {notification && (
              <Notification theme={hunt.hunt_theme} msg={notification} />
            )}

            <Header
              logOut={logOut}
              hunt={hunt}
              logo={hunt.company_logo}
              theme={hunt.hunt_theme}
              player={player}
              status={status}
              lang={lang}
              pages={pages}
              notifications={alertsCount}
              setActiveTab={(event) => {
                setActiveTab(event);
              }}
              // logo={hunt.company_logo}
            />
            <div
              id="gameBody"
              className="gameBody"
              style={{
                direction: lang === "English" ? "ltr" : "rtl",
                fontFamily: "Assistant",
                background: `url(${GC_URL}/${hunt.bg_img.replace(
                  / /g,
                  "%20"
                )})`,
                backgroundColor: hunt.hunt_theme.body.bg,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // maxHeight: "150vh",
                height: "75vh",
                overflowY: "scroll",
                width: isTabletOrMobile ? "100%" : "40%",
                margin: isDesktopOrTablet && "auto",
                borderRight: isDesktopOrTablet && "2px solid",
                borderLeft: isDesktopOrTablet && "2px solid",
                paddingBottom: "15rem",
                // padding: "10px",
              }}
            >
              {!player ? (
                <Welcome
                  setLoginError={setLoginError}
                  loginError={loginError}
                  hunt={hunt}
                  status={status}
                  lang={lang}
                  isTabletOrMobile={isTabletOrMobile}
                  teamOptions={teamOptions}
                  enterGame={(e, type, name) => enterGame(e, type, name)}
                  submitLoading={submitLoading}
                />
              ) : (
                player &&
                !loading && (
                  <div
                    style={
                      {
                        // width: !isDesktopOrTablet ? "100%" : "40%",
                        // margin: isDesktopOrTablet && "auto",
                        // borderRight: isDesktopOrTablet && "2px solid",
                        // borderLeft: isDesktopOrTablet && "2px solid",
                        // minHeight: "100vh",
                        // background: `url(${GC_URL}/${hunt.bg_img.replace(
                        //   / /g,
                        //   "%20"
                        // )})`,
                        // backgroundColor: hunt.hunt_theme.body.bg,
                        // backgroundSize: "contain",
                        // backgroundRepeat: "no-repeat",
                        // maxHeight: "100vh",
                        // height: "100vh",
                        // overflowY: "scroll",
                      }
                    }
                  >
                    {player.type !== "watcher"
                      ? pages[activeTab].component
                      : pages[pages.length - 2].component}
                  </div>
                )
              )}
            </div>
            {hunt &&
              player &&
              player.type !== "watcher" &&
              status === "Active" && (
                <FixedBottomNavigation
                  player={player}
                  status={status}
                  lang={lang}
                  hunt={hunt}
                  activeTab={activeTab}
                  setActiveTab={(event) => {
                    setActiveTab(event);
                  }}
                />
              )}
          </>
        )
      )}
    </div>
  );
}
