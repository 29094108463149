/* eslint-disable */

import { useState, useEffect } from "react";
import ResponsiveDrawer from "../mui/ResponsiveDrawer";
import { FaPlusCircle, FaTasks, FaChartBar } from "react-icons/fa";
import { GiMaze } from "react-icons/gi";
import NewHunt from "./NewHunt";
import Challenges from "./Challenges";
import { useParams } from "react-router-dom";
import ManageHunt from "./ManageHunt";
import Login from "../auth/Login";
import axios from "axios";
import { SERVER_URL, AUTH_API } from "../../Config";
import Loader from "../partials/Loader";
import TasksPage from "./TasksPage";
import Overview from "./Overview";

export default function AdminPanel() {
  const params = useParams();

  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState("");
  const [huntId, setHuntId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userChecked, setUserChecked] = useState(false);
  // const [huntLoading, setHuntLoading] = useState(false);
  // const [hunt, setHunt] = useState([]);

  useEffect(() => {
    setLoading(true);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const expired = Date.now() / 1000 > foundUser.expiry;
      if (expired) {
        localStorage.removeItem("user");
        setUser(null);
        window.location.reload();
      } else {
        setUser(foundUser);
      }
    } else {
      setUser(null);
    }
    if (params.id) {
      setHuntId(params.id);
    } else {
      setHuntId(null);
    }
    setLoading(false);
    setUserChecked(true);
  }, [params]);

  // useEffect(() => {
  //   setLoading(true);
  //   setUser(checkUser());
  //   setLoading(false);
  // }, []);

  const login = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let obj = {
      email: data.get("email"),
      password: data.get("password"),
    };

    await axios
      .post(`${SERVER_URL}/${AUTH_API}/login`, obj)
      .then((res) => {
        if (res.data.email === obj.email) {
          localStorage.setItem("user", JSON.stringify(res.data));
          setUser(res.data);
        } else {
          setLoginError("Email or password is wrong");
        }
      })
      .catch((err) => {
        setLoginError("Email or password is wrong");
      });
  };

  const menu = [
    {
      path: "overview",
      name: "Overview",
      icon: <FaChartBar />,
      content: <Overview user={user} />,
      link: "/admin-panel/overview",
    },
    {
      path: "new-challenge",
      name: "New Hunt",
      icon: <FaPlusCircle />,
      content: <NewHunt user={user} />,
      link: "/admin-panel/new-challenge",
    },
    {
      path: "challenges",
      name: "Hunts",
      icon: <GiMaze />,
      content: <Challenges user={user} />,
      link: "/admin-panel/challenges",
    },
    {
      path: "tasks",
      name: "Tasks",
      icon: <FaTasks />,
      content: <TasksPage user={user} />,
      link: "/admin-panel/tasks",
    },
  ];

  return user ? (
    <>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <ResponsiveDrawer
          menu={menu}
          huntId={huntId}
          ManageHunt={<ManageHunt user={user} />}
        />
      )}
    </>
  ) : (
    userChecked && <Login login={(e) => login(e)} loginError={loginError} />
  );
}
