/* eslint-disable */

import { useState, useEffect } from "react";
import { FaUserSecret, FaListUl } from "react-icons/fa";
import { SERVER_URL, ADMIN_API } from "../../Config";
import Loader from "../partials/Loader";
import CreateApiClient from "../../API";

const api = CreateApiClient();

export default function Overview(props) {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const items = [
    {
      name: "Total Hunts",
      value: count.hunts,
      icon: <FaUserSecret size={40} />,
    },
    {
      name: "Total Tasks",
      value: count.tasks,
      icon: <FaListUl size={40} />,
    },
  ];

  useEffect(() => {
    getCount();
  }, []);

  const getCount = async () => {
    setLoading(true);
    const url = `${SERVER_URL}/${ADMIN_API}/count`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    setCount(data);
    setLoading(false);
  };

  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <div
        className="english inpageSection"
        style={{ display: "block", width: "100%", backgroundColor: "#EEEEEE" }}
      >
        <h1>Overview</h1>
        <hr />
        <div
          style={{
            width: "100%",
            margin: "auto",
            textAlign: "center",
            height: "100vh",
            backgroundColor: "#f6cd65",
            borderRadius: "10px",
          }}
        >
          {loading ? (
            <div
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "4rem",
              }}
            >
              {items.map((item, index) => (
                <div className="iconBox" key={index}>
                  <h2>{item.icon}</h2>
                  <h2>{item.name}</h2>
                  <h2>{item.value}</h2>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
