import DateTimePicker from "react-datetime-picker";

export default function DateTime(props) {
  return (
    <div>
      <DateTimePicker
        onChange={props.onChange}
        value={props.value}
        className="dateTimePicker"
      />
    </div>
  );
}
