export default function FlipCard(props) {
  return (
    <div
      className="flip-card"
      // style={{ height: props.index > 0 ? "160px" : "175px" }}
    >
      <div className="flip-card-inner">
        <div className="flip-card-front">{props.front}</div>
        <div className="flip-card-back">{props.back}</div>
      </div>
    </div>
  );
}
