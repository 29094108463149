/* eslint-disable */

import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { FaListUl, FaPhotoVideo, FaHome } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import { ReactComponent as LeaderBoardIcon } from "../../assets/icons/podium.svg";

export default function FixedBottomNavigation(props) {
  const MuiBottomNavigationAction = styled(BottomNavigationAction)(`
  color: ${props.hunt.hunt_theme.footer.color};
  &.Mui-selected {
    color: ${props.hunt.hunt_theme.footer.bg};
  }
`);

  const [value, setValue] = React.useState(0);

  const menu = [
    {
      name: "home",
      Hebrew: "ראשי",
      English: "Home",
      icon: <FaHome size={30} />,
    },
    // {
    //   name: "guide",
    //   Hebrew: "הוראות",
    //   English: "Guide",
    //   icon: <FaRoute size={30} />,
    // },
    props.player &&
      props.player.type != "watcher" && {
        name: "tasks",
        Hebrew: "משימות",
        English: "Challenges",
        icon: <FaListUl size={30} />,
      },
    {
      name: "feed",
      Hebrew: "פוסטים",
      English: "Feed",
      icon: <FaPhotoVideo size={30} />,
    },
    {
      name: "leaderboard",
      Hebrew: "תוצאות",
      English: "Leaderboard",
      icon: (
        <LeaderBoardIcon
          style={{
            width: "30px",
            height: "30px",
            fill:
              props.activeTab === 3
                ? props.hunt.hunt_theme.footer.bg
                : props.hunt.hunt_theme.footer.color,
          }}
        />
      ),
      // icon: <FaUsers size={30} />,
    },
  ];

  const scrollToTop = () => {
    let gameBody = document.getElementById("gameBody");
    gameBody && gameBody.scroll(0, 0);
  };

  return (
    props.player && (
      <BottomNavigation
        showLabels
        value={props.activeTab}
        onChange={(event, newValue) => {
          scrollToTop();
          props.setActiveTab(newValue);
        }}
        style={{
          position: "fixed",
          bottom: "-5px",
          width: "100%",
          height: "4rem",
          backgroundColor: props.hunt.hunt_theme.footer.bg,
        }}
      >
        {menu.map((item, index) => (
          <MuiBottomNavigationAction
            lang="en"
            sx={{
              backgroundColor:
                props.activeTab === index
                  ? props.hunt.hunt_theme.footer.color
                  : "transparent",
              fontWeight: props.activeTab === index && "bold",
              fontSize: "12px",
            }}
            key={index}
            // label={item[props.lang]}
            label={item.English}
            icon={item.icon}
          />
        ))}
      </BottomNavigation>
    )
  );
}
