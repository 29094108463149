export const checkTiming = (challenge) => {
  let status = "";
  let isActive = challenge.active;
  let now = new Date();
  let start = new Date(challenge.start_date);
  let end = new Date(challenge.end_date);
  let has_start_date = challenge.timing;
  let has_end_date = challenge.has_end_date;

  if (has_start_date && has_end_date) {
    if (now > start && now < end) {
      status = "Active";
    } else if (now < start) {
      status = "Inactive";
    } else if (now > end) {
      status = "Completed";
    }
  } else if (has_start_date && !has_end_date) {
    if (now > start && isActive) {
      status = "Active";
    } else if (now > start && !isActive) {
      status = "Completed";
    } else if (now < start) {
      status = "Inactive";
    }
  } else if (!has_start_date && has_end_date) {
    if (now > end) {
      status = "Completed";
    } else if (now < end && isActive) {
      status = "Active";
    } else {
      status = "Inactive";
    }
  } else if (!has_start_date && !has_end_date) {
    status = isActive ? "Active" : "Completed";
  }

  return status;
};
