/* eslint-disable */

import { useState, useEffect } from "react";
import { SERVER_URL, ADMIN_API } from "../../Config";
import ResponsiveTable from "../partials/ResponsiveTable";
import Loader from "../partials/Loader";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LoadingBtn from "../mui/LoadingBtn";
import Checkbox from "@mui/material/Checkbox";
import LoaderIcon from "react-loader-icon";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import CreateApiClient from "../../API";

const api = CreateApiClient();

const fieldOptions = [
  {
    name: "Task",
    value: "task",
  },
  {
    name: "Player",
    value: "Player",
  },
];

export default function Submissions(props) {
  const [submissions, setSubmissions] = useState([]);
  const [sortedSubmissions, setSortedSubmissions] = useState([]);
  const [sortState, setSortState] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [scoreLoading, setScoreLoading] = useState(null);
  const [score, setScore] = useState(0);
  const [scoreSubmission, setScoreSubmission] = useState("");
  const [submissionsType, setSubmissionsType] = useState("all");
  const [moreLoading, setMoreLoading] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(null);
  const [multiApproveLoading, setMultiApproveLoading] = useState(false);
  // const [pageNumbers, setPageNumbers] = useState([]);
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const [value, setValue] = useState("");
  const [searchField, setSearchField] = useState(fieldOptions[0]);

  const onChangeScore = (e, name) => {
    setScore(e.target.value);
    setScoreSubmission(name);
  };

  useEffect(() => {
    getSubmissions();
  }, [submissionsType, page]);

  // useEffect(() => {
  //   getSubmissions();
  // }, [submissionsType]);

  const getSubmissions = async (initialSearch, clear) => {
    // setPage(page);
    setLoading(true);

    // if (page === 0 && !noLoading) {
    //   setLoading(true);
    // } else {
    //   setMoreLoading(true);
    // }
    const url = `${SERVER_URL}/${ADMIN_API}/submissions?id=${
      props.hunt.hunt._id
    }&unchecked=${submissionsType}&page=${initialSearch ? 1 : page}&q=${
      clear ? "" : value
    }&searchField=${searchField.value}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    // if (data.length < 50) {
    //   setHasMoreResults(false);
    // } else {
    //   setHasMoreResults(true);
    // }

    setTotalPages(data.total_pages);
    // getPageNumbers(data.total_pages);

    if (sortState) {
      let sortedData;
      if (sortState === "asc") {
        sortedData = data.submissions.sort((a, b) =>
          a["name"] > b["name"] ? -1 : 1
        );
      } else {
        sortedData = data.submissions.sort((a, b) =>
          a["name"] < b["name"] ? -1 : 1
        );
      }

      setSubmissions(sortedData);

      // if (page > 1) {
      //   setSubmissions([...submissions, ...sortedData]);
      // } else {
      //   setSubmissions(sortedData);
      // }
    } else {
      setSubmissions(data.submissions);
      // if (page > 1) {
      //   setSubmissions([...submissions, ...data]);
      // } else {
      //   setSubmissions(data);
      // }
    }

    // setPage(page);
    setLoading(false);

    // if (page === 0) {
    //   setLoading(false);
    // } else {
    //   setMoreLoading(false);
    // }
  };

  const addScore = async (sub, bonus) => {
    setScoreLoading(`${sub.name}_${sub.taskDetails.name}`);
    const url = `${SERVER_URL}/${ADMIN_API}/bonus?id=${props.hunt.hunt._id}&bonus=${bonus}&name=${sub.name}&type=${sub.player_type}&task=${sub.taskDetails._id}&submode=true&page=${page}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("POST", url, headers, null);
    getSubmissions(true);
    setScoreLoading(null);
    setScore(0);
    setScoreSubmission("");
  };

  const removeSubmission = async (sub) => {
    setRemoveLoading(`${sub._id}`);
    const url = `${SERVER_URL}/${ADMIN_API}/remove-submission?id=${props.hunt.hunt._id}&name=${sub.name}&score=${sub.score}&type=${sub.player_type}&task=${sub.taskDetails._id}&submode=true&page=${page}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("POST", url, headers, null);
    getSubmissions(true);
    setRemoveLoading(null);
  };

  const selectSubmissions = (sub) => {
    if (selectedSubmissions.includes(sub)) {
      setSelectedSubmissions(selectedSubmissions.filter((s) => s != sub));
    } else {
      setSelectedSubmissions([...selectedSubmissions, sub]);
    }
  };

  const tableHeaders = [
    <Checkbox
      color="success"
      checked={selectedSubmissions?.length === submissions?.length}
      onChange={() => {
        if (selectedSubmissions?.length === submissions?.length) {
          setSelectedSubmissions([]);
        } else {
          setSelectedSubmissions(submissions.map((s) => s._id));
        }
      }}
    />,
    "Remove",
    "Type",
    "Player",
    "Task",
    "Right Answer",
    "Answer",
    "Score",
    "Actions",
  ];

  const sortTable = (field) => {
    let sortedData;
    if (sortState === "asc") {
      sortedData = submissions.sort((a, b) => (a[field] < b[field] ? -1 : 1));
      setSortState("desc");
    } else {
      sortedData = submissions.sort((a, b) => (a[field] > b[field] ? -1 : 1));
      setSortState("asc");
    }
    setSubmissions(sortedData);
    // console.log(sortedData);
    // setSortedSubmissions(sortedData);
  };

  const handleChange = (e) => {
    setSubmissionsType(e.target.value);
    setPage(1);
    // getSubmissions(null, e.target.value);
  };

  const handlePrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePageClick = (pageNum) => {
    setPage(pageNum);
  };

  // const getPageNumbers = (total) => {
  //   const _pageNumbers = [];
  //   const startPage = Math.max(1, page - 5);
  //   const endPage = Math.min(total, page + 5);

  //   for (let i = startPage; i <= endPage; i++) {
  //     _pageNumbers.push(i);
  //   }

  //   setPageNumbers(_pageNumbers);
  // };

  const approveSubmissions = async () => {
    setMultiApproveLoading(true);
    const url = `${SERVER_URL}/${ADMIN_API}/approve-selected-submissions?id=${props.hunt.hunt._id}&page=${page}&unchecked=${submissionsType}&totalPages=${totalPages}`;
    const headers = { "auth-token": props.user.token };

    const body = {
      submissions: submissions
        .filter((submission) => selectedSubmissions.includes(submission._id))
        .map((s) => {
          return {
            _id: s._id,
            score: s.taskDetails.score,
            name: s.name,
            type: s.player_type,
          };
        }),
    };

    const data = await api.fetchData("POST", url, headers, body);
    setMultiApproveLoading(false);
    setSelectedSubmissions([]);
    setSubmissions(data.submissions);
    setTotalPages(data.total_pages);
  };

  return (
    <div className="english inpageSection">
      <h1>Submissions</h1>
      <hr />
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
          columnGap: "5px",
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchField.value}
          label={searchField.name}
          onChange={(e) => {
            const field = fieldOptions.find((s) => s.value === e.target.value);
            setSearchField(field);
          }}
        >
          {fieldOptions.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          label={`Search by ${searchField.name}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={() => getSubmissions(true)}
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={() => {
                    // clear();
                    setValue("");
                    getSubmissions(true, true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <ToggleButtonGroup
        color="primary"
        value={submissionsType}
        exclusive
        onChange={(e) => {
          handleChange(e);
        }}
        aria-label="Platform"
      >
        <ToggleButton value="all">All</ToggleButton>
        <ToggleButton value="unchecked">Unchecked</ToggleButton>
      </ToggleButtonGroup>
      <div className="pagination">
        <button onClick={handlePrevClick} disabled={page === 1}>
          Previous
        </button>
        <div className="pages">
          {[...Array(totalPages)].map((_, index) => {
            const pageNum = index + 1;
            return (
              <button
                key={pageNum}
                onClick={() => handlePageClick(pageNum)}
                className={page === pageNum ? "active" : ""}
              >
                {pageNum}
              </button>
            );
          })}
        </div>
        <button onClick={handleNextClick} disabled={page === totalPages}>
          Next
        </button>
      </div>

      {selectedSubmissions[0] && (
        <LoadingBtn
          style={{
            margin: "10px auto",
            backgroundColor: "#fabe44",
            color: "black",
            borderColor: "#fabe44",
          }}
          txt="Approve Selected Submissions"
          loading={multiApproveLoading}
          onClick={approveSubmissions}
        />
      )}
      <br />
      {loading ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <ResponsiveTable
            selectSubmissions={selectSubmissions}
            selectedSubmissions={selectedSubmissions}
            removeLoading={removeLoading}
            hunt={props.hunt.hunt}
            tasks={props.hunt.tasks}
            headers={tableHeaders}
            removeSubmission={(item) => {
              let confirmed = window.confirm(
                `Are you sure you want to delete this submission?`
              );
              if (confirmed) {
                removeSubmission(item);
              }
            }}
            // data={
            //   sortedSubmissions.length > 0 ? sortedSubmissions : submissions
            // }
            data={submissions}
            tableName="submissions"
            addScore={(sub, score) => addScore(sub, score)}
            scoreLoading={scoreLoading}
            scoreSubmission={scoreSubmission}
            score={score}
            onChangeScore={(e, sub) => onChangeScore(e, sub)}
            isTabletOrMobile={props.isTabletOrMobile}
            sortTable={(field) => sortTable(field)}
          />
          {hasMoreResults && (
            <div style={{ textAlign: "center" }}>
              <LoadingBtn
                style={{
                  // width: isTabletOrMobile ? "50%" : "20%",
                  margin: "10px auto",
                  backgroundColor: "#fabe44",
                  color: "black",
                  borderColor: "#fabe44",
                }}
                txt="Load More"
                loading={moreLoading}
                onClick={() => setPage(page + 1)}
                type="submit"
              />
            </div>
          )}
        </>
      )}
      <div className="pagination">
        <button onClick={handlePrevClick} disabled={page === 1}>
          Previous
        </button>
        <div className="pages">
          {[...Array(totalPages)].map((_, index) => {
            const pageNum = index + 1;
            return (
              <button
                key={pageNum}
                onClick={() => handlePageClick(pageNum)}
                className={page === pageNum ? "active" : ""}
              >
                {pageNum}
              </button>
            );
          })}
        </div>
        <button onClick={handleNextClick} disabled={page === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}
