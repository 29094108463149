/* eslint-disable */

import logo from "../../assets/images/challenge_logo.png";

export default function Home() {
  // useEffect(() => {
  //   window.location.href = "https://clg.co.il";
  // }, []);

  return (
    <div>
      <div
        // id="homeHeader"
        style={{
          backgroundColor: "black",
          borderBottom: "2px solid",
          borderColor: "black",
          padding: "1rem",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img src={logo} style={{ width: "300px" }} />
        </div>
      </div>
    </div>
  );
}
