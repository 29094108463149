/* eslint-disable */

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SERVER_URL, ADMIN_API, PUBLIC_URL } from "../../Config";
import BasicTabs from "../mui/BasicTabs";
import HuntOverview from "./HuntOverview";
import { FaExternalLinkAlt } from "react-icons/fa";
import Participants from "./Participants";
import Submissions from "./Submissions";
import HuntMedia from "./HuntMedia";
import Loader from "../partials/Loader";

import { useMediaQuery } from "react-responsive";

import CreateApiClient from "../../API";
const api = CreateApiClient();

export default function ManageHunt(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const params = useParams();
  const [huntError, setHuntError] = useState(null);
  const [huntLoading, setHuntLoading] = useState(false);
  const [hunt, setHunt] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  // const [notifications, setNotifications] = useState([]);

  const changeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (params.id) {
      getHunt(params.id);
    }
  }, [params]);

  const getHunt = async (id) => {
    setHuntError(null);
    setHuntLoading(true);
    const url = `${SERVER_URL}/${ADMIN_API}/hunt?id=${id}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);

    if (data.hunt && data.hunt._id) {
      setHunt(data);
    } else {
      setHuntError("No Matching Hunts");
    }
    setHuntLoading(false);
  };

  const menu = [
    {
      label: "Overview",
      value: "overview",
      component: (
        <HuntOverview
          huntLoading={huntLoading}
          hunt={hunt}
          isTabletOrMobile={isTabletOrMobile}
          user={props.user}
          getHunt={(id) => getHunt(id)}
          // notifications={hunt.hunt.notifications}
        />
      ),
    },
    {
      label: "Participants",
      value: "participants",
      component: (
        <Participants
          user={props.user}
          hunt={hunt}
          isTabletOrMobile={isTabletOrMobile}
        />
      ),
    },
    {
      label: "Submissions",
      value: "submissions",
      component: (
        <Submissions
          user={props.user}
          hunt={hunt}
          isTabletOrMobile={isTabletOrMobile}
        />
      ),
    },
    {
      label: "Media",
      value: "media",
      component: (
        <HuntMedia
          user={props.user}
          hunt={hunt}
          isTabletOrMobile={isTabletOrMobile}
        />
      ),
    },
    // {
    //   label: "Videos",
    //   value: "videos",
    //   component: <></>,
    // },
  ];

  return (
    <div className="english inpageSection" style={{ padding: "0px" }}>
      {huntError ? (
        <h1>{huntError}</h1>
      ) : hunt && hunt.hunt ? (
        <div>
          <div className="inPageHeader">
            <img
              src={hunt.hunt.company_logo}
              style={{ width: "60px", borderRadius: "10px" }}
            />
            <h1 style={{ marginBottom: "0px" }}>{hunt.hunt.hunt_name}</h1>
            <a
              className="pointer"
              href={`${PUBLIC_URL}/game/${hunt.hunt._id}`}
              target="_blank"
            >
              Public Link{" "}
              <FaExternalLinkAlt

              // onClick={() => {
              //   window.open(
              //     `${PUBLIC_URL}/game/${hunt.hunt._id}`,
              //     "_blank"
              //   );
              // }}
              />
            </a>
            <h5 style={{ color: "gray", margin: "0px" }}>
              {hunt.hunt.company_name}
            </h5>
          </div>
          <hr style={{ marginBottom: "0px" }} />
          <div
            style={{ display: "inline-flex", padding: "0px", width: "100%" }}
          >
            <BasicTabs menu={menu} handleChange={changeTab} value={activeTab} />
          </div>
          <div style={{ backgroundColor: "#efefef", minHeight: "100vh" }}>
            {menu[activeTab].component}
          </div>
        </div>
      ) : (
        huntLoading && (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )
      )}
    </div>
  );
}
