/* eslint-disable */

import { useState, useEffect, useRef } from "react";
import MediaCard from "../MultiLang/MediaCard";
import LoadingBtn from "../mui/LoadingBtn";

const localization = [
  {
    name: "rankMe",
    English: "Rank Me",
    Hebrew: "דרגו אותי:",
  },
  {
    name: "scorePostPre",
    Hebrew: "הוסיפו ל",
    English: "Score ",
  },
  {
    name: "scorePostAfter",
    Hebrew: "ניקוד",
    English: "post",
  },
  {
    name: "scoredPre",
    Hebrew: "הענקתם לפוסט זה ",
    English: "",
  },
  {
    name: "scoredAfter",
    Hebrew: " נקודות",
    English: " Points added by you!",
  },
  {
    name: "loadMore",
    Hebrew: "הצג עוד",
    English: "Load More",
  },
];

const scoreBoxStyle = {
  margin: "4px 4px",
  width: "24px",
  height: "24px",
  borderRadius: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px",
  // backgroundColor: props.hunt.hunt_theme.footer.bg,
  color: "black",
  fontWeight: "bold",
  fontSize: "16px",
};

export default function Submissions(props) {
  // const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const sentinelRef = useRef();

  let page = 0;

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleIntersect, options);
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, []);

  const handleIntersect = (entries) => {
    if (entries[0].isIntersecting) {
      // Load more data
      loadData();
    }
  };

  const loadData = () => {
    if (loading) return;
    setLoading(true);
    page++;

    const startIndex = (page - 1) * 10;
    const endIndex = page * 10;
    const newData = props.data.slice(startIndex, endIndex);

    setData((prevData) => [...prevData, ...newData]);

    // setPage((prevPage) => prevPage + 1);

    setLoading(false);
  };

  return (
    <div style={{ minHeight: "190vh" }}>
      {props.data.map((item, index) => (
        <MediaCard
          socialScores={props.socialScores}
          isDesktopOrTablet={props.isDesktopOrTablet}
          isTabletOrMobile={props.isTabletOrMobile}
          socialBonusValue={props.socialBonusValue}
          socialBonusLoading={props.socialBonusLoading}
          addScore={(to, from, value) => props.addScore(to, from, value)}
          player={props.player}
          scoreBoxStyle={scoreBoxStyle}
          key={index}
          lang={props.lang}
          item={item}
          task={props.tasks.find((t) => t._id === item.task)}
          hunt={props.hunt}
          // isExploding={props.isExploding}
          // confetti={props.confetti}
          content={
            <span
              style={{ fontWeight: "bold", textAlign: "left" }}
              dir={props.lang === "Hebrew" ? "rtl" : "ltr"}
            >
              {/* {localization.find((s) => s.name === "rankMe")[props.lang]} */}

              {/* {localization.find((s) => s.name === "scorePostPre")[props.lang]}
              {item.name}{" "}
              {
                localization.find((s) => s.name === "scorePostAfter")[
                  props.lang
                ]
              } */}
            </span>
          }
          scored={
            props.socialScores?.find(
              (s) => s.to === item._id && s.from === props.player._id
            ) && (
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",

                  fontWeight: "bold",
                  color: props.hunt.hunt_theme.body.color,
                }}
                dir={props.lang === "Hebrew" ? "rtl" : "ltr"}
              >
                {props.isExploding === item._id && props.confetti}
                <span
                  style={{
                    width: "15px",
                    height: "15px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                  }}
                  id={
                    props.socialScores?.find(
                      (s) => s.to === item._id && s.from === props.player._id
                    ).value === 5
                      ? "bronzeRank"
                      : props.socialScores?.find(
                          (s) =>
                            s.to === item._id && s.from === props.player._id
                        ).value === 10
                      ? "silverRank"
                      : "goldRank"
                  }
                ></span>
                {localization.find((s) => s.name === "scoredPre")[props.lang]}{" "}
                {
                  props.socialScores?.find(
                    (s) => s.to === item._id && s.from === props.player._id
                  ).value
                }{" "}
                {localization.find((s) => s.name === "scoredAfter")[props.lang]}
              </div>
            )
          }
        />
      ))}
      {props.hasMoreMedia && (
        <div style={{ textAlign: "center" }}>
          <LoadingBtn
            style={{
              // width: isTabletOrMobile ? "50%" : "20%",
              margin: "10px auto",
              backgroundColor: props.hunt.hunt_theme.body.color,
              color: props.hunt.hunt_theme.body.bg,
              // borderColor: "#fabe44",
            }}
            txt={localization.find((s) => s.name === "loadMore")[props.lang]}
            loading={props.loading}
            onClick={() => props.loadMore()}
            type="submit"
          />
        </div>
      )}
      {/* <div ref={sentinelRef} style={{ height: "1px" }} /> */}
    </div>
  );
}
