/* eslint-disable */

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function PlayerTypes(props) {
  return (
    <div>
      <FormGroup sx={{ display: "block", textAlign: "left" }}>
        {props.options.map((item, index) => (
          <FormControlLabel
            key={index}
            style={{
              justifyContent: "start",
              backgroundColor: "#f5efcd",
              borderRadius: "20px",
              width: props.isTabletOrMobile ? "80%" : "30%",
              margin: "5px",
            }}
            control={
              <Checkbox checked={item.checked} onChange={item.onChange} />
            }
            label={item.label}
          />
        ))}
      </FormGroup>
    </div>
  );
}
