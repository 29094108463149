import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { GC_URL } from "../../Config";

export default function StandardImageList(props) {
  return (
    <ImageList
      sx={{
        width: props.isTabletOrMobile ? 300 : 900,
        minHeight: props.isTabletOrMobile ? "90vh" : "100vh",
        margin: "auto",
        padding: props.isTabletOrMobile ? "30px 0px" : "10px",
        overflowY: "hidden",
      }}
      cols={props.isTabletOrMobile ? 2 : 4}
      rowHeight={300}
    >
      {props.media.map((item, index) => (
        <ImageListItem
          className="pointer"
          key={index}
          onClick={() => props.onAddMediaSelected(item.answer)}
          style={{
            border:
              props.selectedMedia.includes(item.answer) && "5px solid #277FD5",
          }}
        >
          {props.type === "photo" ? (
            <img
              src={`${GC_URL}/${item.answer}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${GC_URL}/${item.answer}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.name}
              loading="lazy"
            />
          ) : (
            <video
              src={`${GC_URL}/${item.answer}`}
              width={props.isTabletOrMobile ? "350" : "164"}
              controls
              // autoPlay
              loop
              style={{ borderRadius: "2px", border: "3px solid" }}
            ></video>
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
}
