/* eslint-disable */

import { useState, useEffect } from "react";
import { FaUsers, FaCheckSquare, FaListUl } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import { MdNotifications } from "react-icons/md";
import LoadingBtn from "../mui/LoadingBtn";
import { io } from "socket.io-client";
import { SERVER_URL } from "../../Config";
import { formatDate } from "../../hooks/formatDate";
import CreateApiClient from "../../API";
import Loader from "../partials/Loader";

const api = CreateApiClient();

export default function HuntOverview(props) {
  const [newNotification, setNewNotification] = useState("");
  const [socket, setSocket] = useState(null);
  const [notified, setNotified] = useState(false);
  const [completions, setCompletions] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);

  useEffect(() => {
    setSocket(io(SERVER_URL));
  }, []);

  const handleChange = (e) => {
    setNewNotification(e.target.value);
  };

  const handleNotification = async () => {
    const headers = { "auth-token": props.user.token };
    const notified = await api.notify(
      newNotification,
      props.hunt.hunt._id,
      headers
    );
    setNotified(true);
    socket.emit("sendNotification", {
      huntId: props.hunt.hunt._id,
      msg: newNotification,
    });

    setNewNotification("");
    props.getHunt(props.hunt.hunt._id);
  };

  useEffect(() => {
    // const names = props.hunt.hunt.players.map((player) => player.name);
    // names.forEach((name) => {
    //   const playerCompletions = props.hunt.hunt.submissions.filter(
    //     (s) => s.name === name
    //   ).length;
    //   if (playerCompletions === props.hunt.tasks.length) {
    //     setCompletions(completions + 1);
    //   }
    // });

    let teams = props.hunt.hunt.players.filter((p) => p.type === "team");
    let others = props.hunt.hunt.players.filter((p) => p.type != "team");
    let teamsCount = teams
      .filter((t) => t.count)
      .reduce((partialSum, a) => partialSum + a.count, 0);
    let total = teamsCount + others.length;
    setTotalParticipants(total);
  }, []);

  const items = [
    {
      name: "Participants",
      value: totalParticipants,
      icon: <FaUsers size={40} />,
    },
    {
      name: "Submissions",
      value: props.hunt?.hunt?.total_submissions,
      icon: <FaCheckSquare size={40} />,
    },
    {
      name: "Tasks",
      value: props.hunt.tasks.length,
      icon: <FaListUl size={40} />,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        margin: "auto",
        textAlign: "center",
        minHeight: "100vh",
        backgroundColor: "#f6cd65",
      }}
    >
      {!props.huntLoading ? (
        <>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            {items.map((item, index) => (
              <div className="iconBox" key={index}>
                <h2>{item.icon}</h2>
                <h2>{item.name}</h2>
                <h2>{item.value}</h2>
              </div>
            ))}
          </div>
          <div
            style={{
              padding: "2rem",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                justifyContent: "start",
                alignItems: "center",
                width: props.isTabletOrMobile ? "100%" : "50%",
                margin: "auto",
              }}
            >
              <MdNotifications size={30} />
              <h2>Notifications</h2>
            </div>
            <div
              style={{
                width: props.isTabletOrMobile ? "100%" : "50%",
                margin: "auto",
                // display: "inline-flex",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <TextField
                id="outlined-name"
                label="New Notification"
                onChange={handleChange}
                placeholder="New Notification"
                value={newNotification}
                required
                fullWidth
              />
              <LoadingBtn
                style={{
                  margin: "10px 0px",
                  width: "100%",
                  backgroundColor: "#fabe44",
                  color: "black",
                  borderColor: "#fabe44",
                }}
                txt="Notify"
                // loading={submitLoading}
                onClick={handleNotification}
                type="submit"
              />
            </div>
            {props.hunt.hunt.notifications.length > 0 && (
              <div>
                {props.hunt.hunt.notifications
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((item, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "50px",
                        padding: "0.5rem 1rem",
                        width: props.isTabletOrMobile ? "100%" : "50%",
                        margin: "10px auto",
                      }}
                    >
                      <p style={{ margin: "0px" }}>{item.msg}</p>
                      <p style={{ margin: "0px" }}>
                        {formatDate(new Date(item.date))}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
}
