import { useEffect, useState } from "react";
import "./App.css";
import Game from "./components/client/Game";
import { verifyPlayer } from "./hooks/verifyPlayer";

function App() {
  const [player, setPlayer] = useState(
    localStorage.getItem("player")
      ? JSON.parse(localStorage.getItem("player"))
      : null
  );

  return (
    <div className="App">
      <Game player={player} />
    </div>
  );
}

export default App;
