export const GC_URL = "https://storage.googleapis.com/hunt-app-bucket";
export const HOST =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost"
    : "https://clggame.com";
// export const PUBLIC_URL =
//   !process.env.NODE_ENV || process.env.NODE_ENV === "development"
//     ? `${HOST}:3000`
//     : `${HOST}`;
// export const SERVER_URL =
//   !process.env.NODE_ENV || process.env.NODE_ENV === "development"
//     ? `${HOST}:8080`
//     : `${HOST}`;

// export const HOST = "http://localhost";
export const PUBLIC_URL = `${HOST}`;
export const SERVER_URL = `${HOST}`;

export const UPLOADS_API = "api/upload";
export const AUTH_API = "api/auth";
export const ADMIN_API = "api/admin";
export const GAME_API = "api/game";
