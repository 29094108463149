/* eslint-disable */

import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { GC_URL } from "../../Config";
import BottomDrawer from "../partials/BottomDrawer";
import AutoPlaySilentVideo from "../../hooks/AutoPlaySilentVideo";

export default function MediaCard(props) {
  const [isExploding, setIsExploding] = useState(null);
  const theme = createTheme({
    direction: props.lang === "Hebrew" ? "rtl" : "ltr",
  });
  // Create rtl cache
  const cacheRtl = createCache({
    key: props.lang === "Hebrew" ? "muirtl" : "muiltr",
    stylisPlugins: props.lang === "Hebrew" && [prefixer, rtlPlugin],
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <div
          className="shadowDiv"
          dir={props.lang === "Hebrew" ? "rtl" : "ltr"}
          style={{
            maxWidth: props.isDesktopOrTablet ? 600 : 345,
            margin: "15px auto",
            backgroundColor: "#c9ddda4f",
            color: props.hunt.hunt_theme.body.color,
            borderRadius: "15px",
            textAlign: props.lang === "Hebrew" ? "right" : "left",
            padding: "1rem 1rem 0.2rem",
          }}
        >
          <h3
            style={{
              fontWeight: "bold",
              marginTop: "0px",
              marginBottom: "8px",
              fontSize: "22px",
            }}
          >
            {props.task && props.task.title}
          </h3>
          {props.item.type === "photo" ? (
            <CardMedia
              component="img"
              height="auto"
              // height={props.isDesktopOrTablet ? "600" : "250"}
              image={`${GC_URL}/${props.item.answer}`}
              alt={`${GC_URL}/${props.item.answer}`}
            />
          ) : (
            <div
              style={{
                textAlign: "center",
                width: !props.isDesktopOrTablet && "320px",
                maxWidth: !props.isDesktopOrTablet && "320px",
              }}
            >
              <AutoPlaySilentVideo
                video={`${GC_URL}/${props.item.answer}`}
                // height={props.isDesktopOrTablet ? "500" : "250"}
                height="auto"
              />
            </div>
          )}
          {props.player.name != props.item.name ? (
            <>
              {props.socialScores?.find(
                (s) => s.to === props.item._id && s.from === props.player._id
              ) ? (
                <div
                  // className="inline-center"
                  dir="ltr"
                  style={{
                    marginTop: "5px",
                    justifyContent: "space-between",
                    display: "inline-flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "bold",
                      margin: "0px",
                      // backgroundColor: props.hunt.hunt_theme.body.color,
                      color: props.hunt.hunt_theme.body.color,
                      padding: "2px 15px",
                      borderRadius: "10px",
                      fontSize: "24px",
                    }}
                  >
                    {props.item.name}
                  </h4>
                  <CardContent
                    style={{
                      padding: "2px 4px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {props.scored}
                  </CardContent>
                </div>
              ) : (
                <div
                  // className="inline-center"
                  dir="ltr"
                  style={{
                    marginTop: "5px",
                    justifyContent: "space-between",
                    display: "inline-flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "bold",
                      margin: "0px",
                      // backgroundColor: props.hunt.hunt_theme.body.color,
                      color: props.hunt.hunt_theme.body.color,
                      padding: "2px 15px",
                      borderRadius: "10px",
                      fontSize: "24px",
                    }}
                  >
                    {props.item.name}
                  </h4>
                  <div>
                    {/* <CardContent
                      style={{ padding: "10px 10px 0px", textAlign: "left" }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          textAlign: "left",
                          color: props.hunt.hunt_theme.body.color,
                        }}
                      >
                        {props.content}
                      </Typography>
                    </CardContent> */}
                    <CardActions disableSpacing>
                      <div
                        onClick={() => {
                          // setIsExploding(props.item._id);
                          props.addScore(props.item, props.player, 5);
                        }}
                        style={props.scoreBoxStyle}
                        id="bronzeRank"
                        // className="star"
                      >
                        {/* {props.socialBonusLoading === props.item._id &&
                        Number(props.socialBonusValue) === 5 ? (
                          <LoaderIcon
                            type={"spin"}
                            style={{
                              width: "20px",
                              height: "20px",
                              fill: props.hunt.hunt_theme.footer.color,
                            }}
                          />
                        ) : (
                          "5"
                        )} */}
                      </div>
                      <div
                        onClick={() => {
                          // setIsExploding(props.item._id);
                          props.addScore(props.item, props.player, 10);
                        }}
                        style={props.scoreBoxStyle}
                        // className="star"
                        id="silverRank"
                      >
                        {/* {isExploding === props.item._id &&
                          Number(props.socialBonusValue) === 10 && (
                            <ConfettiExplosion
                              force={0.6}
                              duration={5000}
                              particleCount={200}
                              height={1600}
                              width={1600}
                            />
                          )} */}
                        {/* {props.socialBonusLoading === props.item._id &&
                        Number(props.socialBonusValue) === 10 ? (
                          <LoaderIcon
                            type={"spin"}
                            style={{
                              width: "20px",
                              height: "20px",
                              fill: props.hunt.hunt_theme.footer.color,
                            }}
                          />
                        ) : (
                          "10"
                        )} */}
                      </div>
                      <div
                        onClick={() => {
                          // setIsExploding(props.item._id);
                          props.addScore(props.item, props.player, 15);
                        }}
                        style={props.scoreBoxStyle}
                        id="goldRank"
                        // className="star"
                      >
                        {/* {isExploding === props.item._id &&
                          Number(props.socialBonusValue) === 15 && (
                            <ConfettiExplosion
                              force={0.6}
                              duration={5000}
                              particleCount={200}
                              height={1600}
                              width={1600}
                            />
                          )} */}
                        {/* {props.socialBonusLoading === props.item._id &&
                        Number(props.socialBonusValue) === 15 ? (
                          <LoaderIcon
                            type={"spin"}
                            style={{
                              width: "20px",
                              height: "20px",
                              fill: props.hunt.hunt_theme.footer.color,
                            }}
                          />
                        ) : (
                          "15"
                        )} */}
                      </div>
                    </CardActions>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              dir="ltr"
              style={{
                marginTop: "5px",
                justifyContent: "space-between",
                display: "inline-flex",
                // alignItems: "start",
                width: "100%",
                padding: "10px 0px",
              }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                  margin: "0px",
                  // backgroundColor: props.hunt.hunt_theme.body.color,
                  color: props.hunt.hunt_theme.body.color,
                  padding: "2px 15px",
                  borderRadius: "10px",
                  fontSize: "24px",
                }}
              >
                {props.item.name}
              </h4>
              {props.hunt.share_enabled && (
                <BottomDrawer
                  url={`${GC_URL}/${props.item.answer}`}
                  hunt={props.hunt}
                />
              )}
            </div>
          )}
          {/* {isExploding === props.item._id && (
            <ConfettiExplosion
              force={0.6}
              duration={5000}
              particleCount={200}
              height={1600}
              width={1600}
            />
          )} */}
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}
