/* eslint-disable */

import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingBtn from "../mui/LoadingBtn";
import Tasks from "./Tasks";
import axios from "axios";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import BasicGrid from "../mui/Grid";
import DateTime from "../partials/DateTime";
import { useMediaQuery } from "react-responsive";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import BasicSelect from "../mui/BasicSelect";
import { SERVER_URL, UPLOADS_API, ADMIN_API } from "../../Config";
// import FileUpload from "./FileUpload";
import MultipleFiles from "./MultipleFiles";
import HuntTheme from "./HuntTheme";
import PlayerTypes from "./PlayerTypes";
import PlayerNames from "./PlayerNames";

import CreateApiClient from "../../API";

const api = CreateApiClient();

export default function EditDetails(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const languages = [
    {
      name: "Hebrew",
      value: "Hebrew",
    },
    {
      name: "English",
      value: "English",
    },
  ];

  const [theme, setTheme] = useState({
    header: {
      bg: props.challenge.hunt_theme.header.bg,
      color: props.challenge.hunt_theme.header.color,
    },
    body: {
      bg: props.challenge.hunt_theme.body.bg,
      color: props.challenge.hunt_theme.body.color,
    },
    boxes: {
      bg: props.challenge.hunt_theme.boxes.bg,
      color: props.challenge.hunt_theme.boxes.color,
    },
    footer: {
      bg: props.challenge.hunt_theme.footer.bg,
      color: props.challenge.hunt_theme.footer.color,
    },
  });

  const [form, setForm] = useState({
    huntName: props.challenge.hunt_name,
    companyName: props.challenge.company_name,
    welcomeMessage: props.challenge.welcome_message,
    instructions: props.challenge.instructions,
    timing: props.challenge.timing,
    hasEndDate: props.challenge.has_end_date,
    startDate: new Date(props.challenge.start_date),
    endDate: new Date(props.challenge.end_date),
    // huntColor: props.challenge.hunt_theme.color,
    // huntBg: props.challenge.hunt_theme.bg,
    language: props.challenge.language,
    maxParticipants: props.challenge.max_team_participants,
    visibleLeaderboard: props.challenge.visible_leaderboard,
    logoFileName: props.challenge.company_logo,
    endingBannerName: props.challenge.ending_banner,
    welcomeBannerName: props.challenge.welcome_banner,
    backgroundFileName: props.challenge.bg_img,
    logoLink: props.challenge.logo_link,
    squareLogoLink: props.challenge.square_logo_link,
    welcomeLink: props.challenge.welcome_link,
    endingLink: props.challenge.ending_link,
    share_enabled: props.challenge.share_enabled,
  });

  const [premadeTeams, setPremadeTeams] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tasksLoading, setTasksLoading] = useState(false);

  const [logoFile, setLogoFile] = useState("");
  const [logoFileName, setLogoFilename] = useState(
    props.challenge.company_logo
  );

  const [logoSquareFile, setLogoSquareFile] = useState("");
  const [logoSquareFileName, setLogoSquareFilename] = useState(
    props.challenge.company_square_logo
  );

  const [endingBanner, setEndingBanner] = useState("");
  const [endingBannerName, setEndingBannerName] = useState(
    props.challenge.ending_banner
  );
  const [welcomeBanner, setWelcomeBanner] = useState("");
  const [welcomeBannerName, setWelcomeBannerName] = useState(
    props.challenge.welcome_banner
  );

  const [backgroundImg, setBackgroundImg] = useState("");
  const [backgroundImgName, setBackgroundImgName] = useState(
    props.challenge.bg_img
  );

  const [hasIndividuals, setHasIndividuals] = useState(
    props.challenge.has_individuals
  );
  const [hasTeams, setHasTeams] = useState(props.challenge.has_teams);
  const [hasWatchers, setHasWatchers] = useState(props.challenge.has_watchers);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [individualName, setIndividualName] = useState(
    props.challenge.individual_name
  );
  const [teamsName, setTeamsName] = useState(props.challenge.teams_name);
  const [watcherName, setWatcherName] = useState("");

  const playerTypes = [
    {
      label: "Individuals",
      checked: hasIndividuals,
      onChange: () => {
        setHasIndividuals(!hasIndividuals);
      },
      name: "Ninja",
      setName: (e) => setIndividualName(e.target.value),
      value: individualName,
    },
    {
      label: "Teams",
      checked: hasTeams,
      onChange: () => setHasTeams(!hasTeams),
      name: "Hunters",
      setName: (e) => setTeamsName(e.target.value),
      value: teamsName,
    },
    {
      label: "Watchers",
      checked: hasWatchers,
      onChange: () => setHasWatchers(!hasWatchers),
      name: "Watcher",
      setName: (e) => setWatcherName(e.target.value),
      value: watcherName,
    },
  ];

  const onUploadFile = (e) => {
    let _file = files.find((f) => f.id === e.target.id);
    _file.setFile(e.target.files[0]);
    _file.setFilename(e.target.files[0].name);
  };

  const onRemoveFile = (e) => {
    let _file = files.find((f) => `${f.id}_${f.id}` === e.target.id);
    _file.setFile("");
    _file.setFilename("");
  };

  const files = [
    {
      id: "1",
      file: logoFile,
      filename: logoFileName,
      setFile: (e) => setLogoFile(e),
      setFilename: (e) => setLogoFilename(e),
    },
    {
      id: "1_2",
      file: logoSquareFile,
      filename: logoSquareFileName,
      setFile: (e) => setLogoSquareFile(e),
      setFilename: (e) => setLogoSquareFilename(e),
    },
    {
      id: "1_1",
      file: welcomeBanner,
      filename: welcomeBannerName,
      setFile: (e) => setWelcomeBanner(e),
      setFilename: (e) => setWelcomeBannerName(e),
    },
    {
      id: "2",
      file: endingBanner,
      filename: endingBannerName,
      setFile: (e) => setEndingBanner(e),
      setFilename: (e) => setEndingBannerName(e),
    },
    {
      id: "3",
      file: backgroundImg,
      filename: backgroundImgName,
      setFile: (e) => setBackgroundImg(e),
      setFilename: (e) => setBackgroundImgName(e),
    },
  ];

  useEffect(() => {
    populateTeams(props.challenge);
    getTasks(props.challenge._id);
  }, []);

  const populateTeams = (challenge) => {
    let _teams = challenge.players
      .filter((s) => s.type === "team")
      .map((item) => item.name);
    setPremadeTeams(_teams);
  };

  const handleThemeChange = (event, part, fieldName) => {
    setTheme({
      ...theme,
      [part]: { ...theme[part], [fieldName]: event.target.value },
    });
  };

  const handleChange = (event, fieldName) => {
    setForm({ ...form, [fieldName]: event.target.value });
  };

  const handleCheck = (fieldName, value) => {
    setForm({ ...form, [fieldName]: value });
  };

  const handleSelect = (e, dateType) => {
    setForm({ ...form, [dateType]: new Date(e) });
  };

  const getTasks = async (id) => {
    setTasksLoading(true);
    const url = `${SERVER_URL}/${ADMIN_API}/tasks-by-hunt?id=${id}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    let sortedTasks = data.sort(
      (a, b) =>
        a.hunts.find((h) => h.id.toString() === id.toString()).position -
        b.hunts.find((h) => h.id.toString() === id.toString()).position
    );
    setTasks(sortedTasks);
    setTasksLoading(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);
    const formData = new FormData();
    formData.append("huntName", form.huntName);
    formData.append("companyName", form.companyName);
    formData.append("theme", JSON.stringify(theme));
    formData.append("welcomeMessage", form.welcomeMessage);
    formData.append("instructions", form.instructions);
    formData.append("timing", form.timing);
    formData.append("hasEndDate", form.hasEndDate);
    formData.append("startDate", form.startDate);
    formData.append("endDate", form.endDate);
    formData.append(
      "timeZone",
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    formData.append("language", form.language);
    formData.append("maxParticipants", form.maxParticipants);
    formData.append("premade_teams", JSON.stringify(premadeTeams));
    formData.append("has_individuals", hasIndividuals);
    formData.append("has_teams", hasTeams);
    formData.append("share_enabled", form.share_enabled);
    formData.append("has_watchers", hasWatchers);
    formData.append("individual_name", individualName);
    formData.append("teams_name", teamsName);
    formData.append("watcher_name", watcherName);
    formData.append("visibleLeaderboard", form.visibleLeaderboard);
    formData.append("logoFileName", logoFileName);
    formData.append("logoSquareFileName", logoSquareFileName);
    formData.append("endingBannerName", endingBannerName);
    formData.append("welcome_banner", welcomeBannerName);
    formData.append("bg_img", backgroundImgName);
    formData.append("logo_link", form.logoLink);
    formData.append("square_logo_link", form.squareLogoLink);
    formData.append("welcome_link", form.welcomeLink);
    formData.append("ending_link", form.endingLink);
    formData.append("tasks", JSON.stringify(tasks));
    formData.append("newLogo", logoFile ? true : false);

    let images = [
      logoFile,
      logoSquareFile,
      endingBanner,
      welcomeBanner,
      backgroundImg,
    ];

    if (tasks && tasks.length > 0) {
      tasks.forEach((task) => {
        formData.append("taskImages", task.file);
      });
    }

    images.forEach((file) => {
      formData.append("images", file);
    });

    await axios
      .post(
        `${SERVER_URL}/${UPLOADS_API}/update-hunt?id=${props.challenge._id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "auth-token": props.user.token,
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          setSuccess(`${form.huntName} has been updated!`);
        }, 3000);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.message) {
          setError(err.response.message);
        }
      });
    setSubmitLoading(false);
  };

  const themeOptions = [
    {
      name: "header",
    },
    {
      name: "body",
    },
    {
      name: "boxes",
    },
    {
      name: "footer",
    },
  ];

  const formComponents = [
    {
      id: 1,
      xs: 6,
      component: (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "90%" },
          }}
          autoComplete="off"
          style={{
            textAlign: "center",
          }}
        >
          <h4 style={{ textAlign: "left" }}>Basic Information:</h4>
          <TextField
            id="outlined-name"
            label="Hunt Name"
            onChange={(e) => handleChange(e, "huntName")}
            // placeholder={props.challenge.hunt_name}
            value={form.huntName}
            required
            fullWidth
          />
          <br />
          <TextField
            id="outlined-name"
            label="Company Name"
            onChange={(e) => handleChange(e, "companyName")}
            placeholder="Google"
            value={form.companyName}
            required
          />
          {/* <br />
          <TextareaAutosize
            aria-label="Welcome Message"
            minRows={5}
            maxRows={6}
            placeholder="Welcome Message"
            style={{
              width: "90%",
              maxWidth: "90%",
              padding: "10px",
              outline: "none",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "4px",
            }}
            onChange={(e) => handleChange(e, "welcomeMessage")}
            value={form.welcomeMessage}
          /> */}
          <br />
          <TextareaAutosize
            aria-label="Hunt Instructions"
            minRows={5}
            maxRows={6}
            placeholder="Hunt Instructions"
            style={{
              width: "90%",
              maxWidth: "90%",
              padding: "10px",
              outline: "none",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "4px",
            }}
            onChange={(e) => handleChange(e, "instructions")}
            value={form.instructions}
          />
          <br />
          <TextField
            id="outlined-name"
            label="Logo Link"
            onChange={(e) => handleChange(e, "logoLink")}
            placeholder="Logo Link"
            value={form.logoLink}
            fullWidth
          />
          <br />
          <TextField
            id="outlined-name"
            label="Square Logo Link"
            onChange={(e) => handleChange(e, "squareLogoLink")}
            placeholder="Square Logo Link"
            value={form.squareLogoLink}
            fullWidth
          />
          <br />
          <TextField
            id="outlined-name"
            label="Welcome Banner Link"
            onChange={(e) => handleChange(e, "welcomeLink")}
            placeholder="Welcome Banner Link"
            value={form.welcomeLink}
            fullWidth
          />
          <br />
          <TextField
            id="outlined-name"
            label="Ending Banner Link"
            onChange={(e) => handleChange(e, "endingLink")}
            placeholder="Ending Banner Link"
            value={form.endingLink}
            fullWidth
          />
          <br />
          <FormGroup>
            <FormControlLabel
              style={{
                justifyContent: "start",
                backgroundColor: "#f5efcd",
                borderRadius: "20px",
                width: isTabletOrMobile ? "70%" : "50%",
                margin: "5px",
              }}
              control={
                <Checkbox
                  checked={form.share_enabled}
                  onChange={() => {
                    handleCheck("share_enabled", !form.share_enabled);
                  }}
                />
              }
              label="Share Enabled?"
            />
          </FormGroup>
        </Box>
      ),
    },
    {
      id: 2,
      xs: 6,
      component: (
        <Box
          style={{
            textAlign: "left",
          }}
          sx={{
            "& > :not(style)": { m: 1, width: "90%" },
          }}
        >
          <h4 style={{ textAlign: "left" }}>Hunt Branding:</h4>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
              backgroundColor: "#F8C937",
              padding: "15px",
              borderRadius: "10px",
            }}
          >
            <HuntTheme
              options={themeOptions}
              theme={theme}
              handleChange={(e, part, field) =>
                handleThemeChange(e, part, field)
              }
            />
          </div>
          <br />
          <BasicSelect
            options={languages}
            label={form.language}
            style={{ width: "90%" }}
            onChange={(e) => handleChange(e, "language")}
            value={form.language}
          />
          <br />
          <MultipleFiles
            files={files}
            onChange={onUploadFile}
            logoFile={form.logoFileName}
            endingBanner={form.endingBannerName}
            welcomeBanner={form.welcomeBannerName}
            bgImg={form.backgroundFileName}
            onRemoveFile={onRemoveFile}
          />
          <br />
        </Box>
      ),
    },
    {
      id: 3,
      xs: 6,
      component: (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "90%" },
          }}
          autoComplete="off"
          style={{
            textAlign: "center",
          }}
        >
          <h4 style={{ textAlign: "left" }}>Hunt Timing:</h4>
          <FormGroup>
            <FormControlLabel
              style={{
                justifyContent: "start",
                backgroundColor: "#f5efcd",
                borderRadius: "20px",
                width: isTabletOrMobile ? "70%" : "50%",
                margin: "5px",
              }}
              control={
                <Checkbox
                  checked={form.timing}
                  onChange={() => {
                    handleCheck("timing", !form.timing);
                  }}
                />
              }
              label="Start Date"
            />
          </FormGroup>
          <>
            <FormGroup>
              <FormControlLabel
                style={{
                  justifyContent: "start",
                  backgroundColor: "#f5efcd",
                  borderRadius: "20px",
                  width: isTabletOrMobile ? "70%" : "50%",
                  margin: "5px",
                }}
                control={
                  <Checkbox
                    checked={form.hasEndDate}
                    onChange={() => {
                      handleCheck("hasEndDate", !form.hasEndDate);
                    }}
                  />
                }
                label="End Date"
              />
            </FormGroup>
            {form.timing && (
              <>
                {" "}
                <h4 style={{ textAlign: "left" }}>Start Date:</h4>
                <DateTime
                  onChange={(e) => handleSelect(e, "startDate")}
                  value={form.startDate}
                />
              </>
            )}
            {form.hasEndDate && (
              <>
                <h4 style={{ textAlign: "left" }}>End Date:</h4>
                <DateTime
                  onChange={(e) => handleSelect(e, "endDate")}
                  value={form.endDate}
                />
              </>
            )}
          </>
        </Box>
      ),
    },
    {
      id: 4,
      xs: 6,
      component: (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "90%" },
          }}
          autoComplete="off"
          style={{
            textAlign: "center",
          }}
        >
          <h4 style={{ textAlign: "left" }}>Participants:</h4>
          <TextField
            type="number"
            id="outlined-number"
            label="Max Participants Per Team"
            onChange={(e) => handleChange(e, "maxParticipants")}
            placeholder="Max Participants Per Team"
            value={form.maxParticipants}
            required
            fullWidth
          />
          <TextField
            type="text"
            id="outlined-text2"
            label="Pre-made Teams (Comma Separated)"
            onChange={(e) => {
              setPremadeTeams(e.target.value.split(","));
            }}
            placeholder="Pre-made Teams"
            value={premadeTeams.join(",")}
            required
            fullWidth
          />
          <FormGroup>
            <FormControlLabel
              style={{
                justifyContent: "start",
                backgroundColor: "#f5efcd",
                borderRadius: "20px",
                width: isTabletOrMobile ? "80%" : "50%",
                margin: "5px",
              }}
              control={
                <Checkbox
                  checked={form.visibleLeaderboard}
                  onChange={(e) => {
                    handleCheck("visibleLeaderboard", !form.visibleLeaderboard);
                  }}
                />
              }
              label="Visible Leaderboard"
            />
          </FormGroup>
          <PlayerTypes
            options={playerTypes}
            isTabletOrMobile={isTabletOrMobile}
          />
          <PlayerNames
            options={playerTypes}
            isTabletOrMobile={isTabletOrMobile}
          />
        </Box>
      ),
    },
    {
      id: 5,
      xs: 12,
      component: (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "100%", paddingLeft: "0px" },
          }}
          autoComplete="off"
          style={{
            textAlign: "center",
          }}
        >
          <h1 style={{ textAlign: "center" }}>Hunt Tasks:</h1>
          <hr style={{ margin: "0px" }} />
          <br />
          <Tasks
            tasksLoading={tasksLoading}
            tasks={tasks}
            setTasks={setTasks}
            user={props.user}
            editMode={true}
          />
        </Box>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <BasicGrid data={formComponents} />
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingBtn
          style={{
            width: isTabletOrMobile ? "100%" : "20%",
            margin: "10px auto",
            backgroundColor: "#fabe44",
            color: "black",
            borderColor: "#fabe44",
          }}
          txt="Save Changes"
          loading={submitLoading}
          onClick={submitForm}
          type="submit"
        />
        <br />
        {error && <span className="error">{error}</span>}
        {success && <span className="success">{success}</span>}
      </div>
    </div>
  );
}
