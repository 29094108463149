import axios from "axios";
import { SERVER_URL, ADMIN_API } from "./Config";

const CreateApiClient = () => {
  return {
    fetchData: async (method, url, headers, data, cancelToken) => {
      let config = {
        method: method,
        url: url,
        headers: headers,
        data: data,
        cancelToken: cancelToken,
      };

      return await axios(config)
        .then((response) => response.data)
        .catch((error) => error);
    },
    updateData: async (method, url, headers, data) => {
      let config = {
        method: method,
        url: url,
        headers: headers,
        data: data,
      };

      return await axios(config)
        .then((response) => response.data)
        .catch((error) => error.response.data);
    },
    downloadMedia: async (method, url, headers, data) => {
      // let config = {
      //   method: method,
      //   url: url,
      //   headers: headers,
      //   data: data,
      //   responseType: "arraybuffer",
      // };

      return await axios
        .post(url, data, { headers: headers })
        .then((response) => response)
        .catch((error) => error.response);
    },
    notify: async (msg, id, headers) => {
      let config = {
        method: "POST",
        url: `${SERVER_URL}/${ADMIN_API}/send-notification?id=${id}&msg=${msg}`,
        headers: headers,
        data: {},
      };

      return await axios(config)
        .then((response) => response.data)
        .catch((error) => error.response.data);
    },
  };
};

export default CreateApiClient;
