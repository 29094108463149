import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme, props }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  // minHeight: props.minHeight,
}));

export default function BasicGrid(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
        {props.data.map((_item, index) => (
          <Grid key={index} item xs={_item.xs}>
            <Item
              className="gridItem"
              style={{
                minHeight: index < 2 ? "65rem" : "25rem",
              }}
            >
              {_item.component}
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
