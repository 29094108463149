/* eslint-disable */

import { GC_URL } from "../../Config";
import { useParams } from "react-router-dom";

export default function Post() {
  const params = useParams();

  return (
    <div
      style={{
        margin: "0px",
        background: "#0e0e0e",
        height: "100vh",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src={`${GC_URL}/${params.id}`}
        style={{
          display: "block",
          margin: "auto",
          cursor: "zoom-in",
          backgroundColor: "hsl(0, 0%, 90%)",
          transition: "background-color 300ms",
        }}
      />
    </div>
  );
}
