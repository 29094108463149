/* eslint-disable */

import { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  FaTrashAlt,
  FaEdit,
  FaExpandAlt,
  FaLink,
  FaCamera,
  FaVideo,
  FaQuestionCircle,
  FaList,
  FaCheckCircle,
  FaCopy,
} from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { PUBLIC_URL, GC_URL } from "../../Config";
import TextField from "@mui/material/TextField";
import LoaderIcon from "react-loader-icon";
import FullScreenDialog from "../mui/FullScreenDialog";
import Checkbox from "@mui/material/Checkbox";

export default function ResponsiveTable(props) {
  const [mediaOpen, setMediaOpen] = useState(null);

  const handleClose = () => {
    setMediaOpen(null);
  };

  const checkTiming = (challenge) => {
    let status = "";
    let isActive = challenge.active;
    let now = new Date();
    let start = new Date(challenge.start_date);
    let end = new Date(challenge.end_date);
    let has_start_date = challenge.timing;
    let has_end_date = challenge.has_end_date;

    if (has_start_date && has_end_date) {
      if (now > start && now < end) {
        status = "Active";
      } else if (now < start) {
        status = "Inactive";
      } else if (now > end) {
        status = "Completed";
      }
    } else if (has_start_date && !has_end_date) {
      if (now > start && isActive) {
        status = "Active";
      } else if (now > start && !isActive) {
        status = "Completed";
      } else if (now < start) {
        status = "Inactive";
      }
    } else if (!has_start_date && has_end_date) {
      if (now > end) {
        status = "Completed";
      } else if (now < end && isActive) {
        status = "Active";
      } else {
        status = "Inactive";
      }
    } else if (!has_start_date && !has_end_date) {
      status = isActive ? "Active" : "Completed";
    }

    return status;
  };

  const icons = [
    {
      type: "photo",
      icon: <FaCamera size={25} />,
    },
    {
      type: "video",
      icon: <FaVideo size={25} />,
    },
    {
      type: "qa",
      icon: <FaQuestionCircle size={25} />,
    },
    {
      type: "multi",
      icon: <FaList size={25} />,
    },
  ];

  const tableCells = [
    {
      name: "challenges",
      component: (item, index) => {
        return (
          <Tr key={index}>
            <Td>
              <img
                src={item.company_square_logo}
                style={{ width: "30px", borderRadius: "5px" }}
              />
            </Td>
            <Td>{item.hunt_name}</Td>
            <Td>{checkTiming(item)}</Td>
            <Td>{item.players.length}</Td>
            {/* <Td>{props.checkCompletions(item)}</Td> */}
            <Td style={{ justifyContent: "space-evenly", display: "flex" }}>
              {props.deleteLoading === item._id ? (
                <LoaderIcon
                  type={"spin"}
                  style={{
                    width: "20px",
                    height: "20px",
                    fill: "black",
                    margin: "0px",
                  }}
                />
              ) : (
                <FaTrashAlt
                  className="pointer"
                  onClick={() => props.deleteChallenge(item)}
                />
              )}
              <FaEdit
                className="pointer"
                onClick={() => props.openEditDialog(item)}
              />
              <FaExpandAlt
                className="pointer"
                onClick={() => props.manageHunt(item)}
              />
              <FaLink
                className="pointer"
                onClick={() => {
                  window.open(`${PUBLIC_URL}/game/${item._id}`, "_blank");
                }}
              />
              {props.copyLoading === item._id ? (
                <LoaderIcon
                  type={"spin"}
                  style={{
                    width: "20px",
                    height: "20px",
                    fill: "black",
                    margin: "0px",
                  }}
                />
              ) : (
                <FaCopy
                  className="pointer"
                  onClick={() => props.copyHunt(item)}
                />
              )}
            </Td>
          </Tr>
        );
      },
    },
    {
      name: "participants",
      component: (item, index) => {
        return (
          <Tr key={index}>
            <Td
              style={{
                width: !props.isTabletOrMobile && "15%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: props.isTabletOrMobile ? "150px" : "200px",
                  padding: "0px 10px",
                  // width: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: "50px",
                    padding: "5px",
                    textAlign: "center",
                    color: "white",
                    width: "40px",
                    height: "40px",
                    margin: "0px 10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => props.removeParticipant(item)}
                  className="pointer"
                >
                  {props.removeLoading === item._id ? (
                    <LoaderIcon
                      type={"spin"}
                      style={{
                        width: "20px",
                        height: "20px",
                        fill: "white",
                      }}
                    />
                  ) : (
                    "X"
                  )}
                </div>
                {item.name}
              </div>
            </Td>
            <Td className="capitalize">{item.type}</Td>
            <Td className="capitalize">
              {item.type === "team" && item.count > 0
                ? item.count
                : item.type === "team"
                ? 0
                : "1"}
            </Td>
            <Td>
              <div style={{ display: "flex" }}>
                <div className="progress-container">
                  <div
                    className="progressBar progress"
                    style={{
                      width:
                        props.hunt.submissions.filter(
                          (s) => s.name === item.name
                        ).length === 0
                          ? "20%"
                          : `${Math.round(
                              (props.hunt.submissions.filter(
                                (s) => s.name === item.name
                              ).length /
                                props.tasks.length) *
                                100
                            )}%`,
                      backgroundColor:
                        props.hunt.submissions.filter(
                          (s) => s.name === item.name
                        ).length === 0 && "transparent",
                    }}
                  ></div>
                </div>
                <div style={{ width: "25%", fontWeight: "bold" }}>
                  {Math.round(
                    (props.hunt.submissions.filter((s) => s.name === item.name)
                      .length /
                      props.tasks.length) *
                      100
                  )}
                  %
                </div>
              </div>
            </Td>
            <Td>{item.score}</Td>
            <Td
              style={{
                width: !props.isTabletOrMobile && "15%",
              }}
            >
              <div
                style={{
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                <TextField
                  type="number"
                  id="outlined-number"
                  label="Bonus"
                  onChange={(e) => props.onChangeBonus(e, item.name)}
                  placeholder="Bonus"
                  value={props.bonusPlayer === item.name ? props.bonus : 0}
                  style={{ width: "80%" }}
                />
                <div
                  style={{
                    backgroundColor: "#73C25B",
                    borderRadius: "5px",
                    padding: "5px",
                    textAlign: "center",
                    color: "white",
                    // width: isTabletOrMobile ? "25px" : "40px",
                    // height: isTabletOrMobile ? "25px" : "40px",
                    // margin: isTabletOrMobile && "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px 4px",
                    width: "20%",
                  }}
                  onClick={() => props.addBonus(item, props.bonus)}
                  className="pointer"
                >
                  {props.bonusLoading === item.name ? (
                    <LoaderIcon
                      type={"spin"}
                      style={{
                        width: "20px",
                        height: "20px",
                        fill: "white",
                      }}
                    />
                  ) : (
                    "+"
                  )}
                </div>
              </div>
            </Td>
          </Tr>
        );
      },
    },
    {
      name: "submissions",
      component: (item, index) => {
        return (
          item.taskDetails && (
            <Tr
              key={index}
              style={{
                backgroundColor:
                  (item.status === "checked" || item.taskDetails.autoScore) &&
                  "lightgray",
                fontWeight:
                  item.score === 0 && !item.taskDetails.autoScore && "bold",
              }}
            >
              <Td>
                <Checkbox
                  color="success"
                  onChange={() => {
                    props.selectSubmissions(item._id);
                  }}
                  checked={props.selectedSubmissions.includes(item._id)}
                />
              </Td>
              <Td
                style={{
                  width: !props.isTabletOrMobile && "5%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // width: props.isTabletOrMobile ? "150px" : "200px",
                    padding: "0px 10px",
                    // width: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50px",
                      padding: "5px",
                      textAlign: "center",
                      color: "white",
                      width: "40px",
                      height: "40px",
                      margin: "0px 10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => props.removeSubmission(item)}
                    className="pointer"
                  >
                    {props.removeLoading === item._id ? (
                      <LoaderIcon
                        type={"spin"}
                        style={{
                          width: "20px",
                          height: "20px",
                          fill: "white",
                        }}
                      />
                    ) : (
                      "X"
                    )}
                  </div>
                </div>
              </Td>
              <Td>{icons.find((i) => i.type === item.type).icon}</Td>
              <Td>{item.name}</Td>
              <Td>{item.taskDetails.name}</Td>
              <Td>
                {item.type === "qa"
                  ? item.taskDetails.answer
                  : item.type === "multi"
                  ? item.taskDetails.options.find(
                      (t) => t.index === item.taskDetails.correct_option
                    ).value
                  : "X"}
              </Td>
              <Td>
                {item.type === "photo" ? (
                  <>
                    <img
                      src={`${GC_URL}/${item.answer}`}
                      width="60"
                      onClick={() => {
                        setMediaOpen(item);
                      }}
                      className="pointer"
                      style={{ borderRadius: "5px" }}
                    />
                    {mediaOpen && (
                      <FullScreenDialog
                        component={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // padding: "10rem",
                              height: "100vh",
                              // background: `url(${GC_URL}/${mediaOpen.answer.replace(
                              //   " ",
                              //   "%20"
                              // )})`,
                              // backgroundRepeat: "no-repeat",
                              // backgroundSize: "contain",
                              // backgroundPosition: "center",
                            }}
                            // src={`${GC_URL}/${item.answer}`}
                          >
                            <img
                              src={`${GC_URL}/${mediaOpen.answer.replace(
                                " ",
                                "%20"
                              )}`}
                            />
                          </div>
                        }
                        open={
                          mediaOpen.taskDetails.name === item.taskDetails.name
                        }
                        handleClose={handleClose}
                        headline={mediaOpen.taskDetails.title}
                      />
                    )}
                  </>
                ) : item.type === "multi" ? (
                  <p>
                    {item.answer === item.taskDetails.correct_option ? (
                      <FaCheckCircle size={35} style={{ fill: "green" }} />
                    ) : (
                      <ImCross size={35} style={{ fill: "red" }} />
                    )}
                  </p>
                ) : item.type === "qa" ? (
                  item.answer
                ) : (
                  item.type === "video" && (
                    <video
                      src={`${GC_URL}/${item.answer}`}
                      width={props.isTabletOrMobile ? "150" : "300"}
                      controls
                      // autoPlay
                      loop
                      style={{ borderRadius: "2px", border: "3px solid" }}
                    ></video>
                  )
                )}
              </Td>
              <Td>{item.taskDetails.score}</Td>
              <Td
                style={{
                  width: !props.isTabletOrMobile && "15%",
                }}
              >
                <div
                  style={{
                    justifyContent: "space-evenly",
                    display: "flex",
                  }}
                >
                  <TextField
                    className="font20"
                    type="number"
                    id="outlined-number"
                    label="Score"
                    onChange={(e) =>
                      props.onChangeScore(
                        e,
                        `${item.name}_${item.taskDetails.name}`
                      )
                    }
                    placeholder="Score"
                    value={
                      props.scoreSubmission ===
                      `${item.name}_${item.taskDetails.name}`
                        ? props.score
                        : item.taskDetails.score
                    }
                    style={{ width: "80%", fontSize: "20px" }}
                  />
                  <div
                    style={{
                      backgroundColor: "#73C25B",
                      borderRadius: "5px",
                      padding: "5px",
                      textAlign: "center",
                      color: "white",
                      // width: isTabletOrMobile ? "25px" : "40px",
                      // height: isTabletOrMobile ? "25px" : "40px",
                      // margin: isTabletOrMobile && "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0px 4px",
                      width: "20%",
                    }}
                    onClick={() =>
                      props.addScore(
                        item,
                        props.scoreSubmission ===
                          `${item.name}_${item.taskDetails.name}`
                          ? props.score
                          : item.taskDetails.score
                      )
                    }
                    className="pointer"
                  >
                    {props.scoreLoading ===
                    `${item.name}_${item.taskDetails.name}` ? (
                      <LoaderIcon
                        type={"spin"}
                        style={{
                          width: "20px",
                          height: "20px",
                          fill: "white",
                        }}
                      />
                    ) : (
                      "→"
                    )}
                  </div>
                </div>
              </Td>
            </Tr>
          )
        );
      },
    },
  ];

  return (
    <Table>
      <Thead>
        <Tr>
          {props.headers.map((th, index) => (
            <Th
              key={index}
              className={
                props.tableName === "submissions" && index === 1
                  ? "pointer"
                  : null
              }
              onClick={() =>
                props.tableName === "submissions" &&
                index === 1 &&
                props.sortTable("name")
              }
            >
              {th}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody className="center-table">
        {props.data.map((item, index) =>
          tableCells
            .find((s) => s.name === props.tableName)
            .component(item, index)
        )}
      </Tbody>
    </Table>
  );
}
