// import { ReactNotifications, Store } from "react-notifications-component";
import CampaignIcon from "@mui/icons-material/Campaign";
import IconButton from "@mui/material/IconButton";

export default function Notification(props) {
  const boxStyle = {
    position: "absolute",
    top: "0",
    backgroundColor: props.theme.footer.bg,
    color: props.theme.footer.color,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    opacity: "0.9",
    width: "100%",
    zIndex: "999999999999",
  };

  return (
    // <label for="one" className="alert-message" style={boxStyle}>
    //   <MdNotifications size={35} style={{ fill: props.theme.footer.color }} />
    //   {/* <img src={clgLogo} style={{ width: "50px", borderRadius: "50px" }} /> */}
    //   <span style={{ width: "70%" }}>{props.msg}</span>
    //   {/* <span className="close">×</span> */}
    // </label>
    <div id="notificationBox" className="notification" style={boxStyle}>
      <IconButton size="large" aria-label="notifications" color="inherit">
        <CampaignIcon
          sx={{ fill: props.theme.footer.color, fontSize: "32px" }}
        />
      </IconButton>
      <span style={{ width: "80%" }}>{props.msg}</span>
      <span style={{ width: "10%" }}></span>
    </div>
  );
}
