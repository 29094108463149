import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../../assets/images/clg_logo_square.png";
import { FaUserSecret } from "react-icons/fa";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://clg.co.il/" target="_blank">
        Challenge Group
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login(props) {
  //   const [user, setUser] = useState(null);
  //   const [loginError, setLoginError] = useState("");

  //   const login = async (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);
  //     let obj = {
  //       email: data.get("email"),
  //       password: data.get("password"),
  //     };

  //     await axios
  //       .post(`${SERVER_URL}/${AUTH_API}/login`, obj)
  //       .then((res) => {
  //         if (res.data.email === obj.email) {
  //           localStorage.setItem("user", JSON.stringify(res.data));
  //           setUser(res.data);
  //           props.checkUser();
  //           window.location.reload();
  //         } else {
  //           setLoginError("Email or password is wrong");
  //         }
  //       })
  //       .catch((err) => {
  //         setLoginError("Email or password is wrong");
  //       });
  //   };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "6rem",
            }}
          >
            <Avatar
              sx={{
                m: 2,
                bgcolor: "#fabe44",
                color: "black",
                width: 60,
                height: 60,
              }}
            >
              <FaUserSecret size={30} />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={(e) => props.login(e)}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                className="yellowBtn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: "#fabe44", color: "black" }}
              >
                Sign In
              </Button>
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "red" }}>{props.loginError}</span>
              </div>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
