/* eslint-disable */

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function MultiRadio(props) {
  const theme = createTheme({
    direction: props.lang === "Hebrew" ? "rtl" : "ltr",
  });
  // Create rtl cache
  const cacheRtl = createCache({
    key: props.lang === "Hebrew" ? "muirtl" : "muiltr",
    stylisPlugins: props.lang === "Hebrew" && [prefixer, rtlPlugin],
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <FormControl dir={props.lang === "Hebrew" ? "rtl" : "ltr"}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {props.options.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio style={{ color: props.radioColor }} />}
                label={item.value}
                onChange={() => props.onChange(item.index)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </ThemeProvider>
    </CacheProvider>
  );
}
