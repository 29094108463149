/* eslint-disable */

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../assets/images/challenge_logo.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

import Loader from "../partials/Loader";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const location = useLocation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { _window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [tabLoading, setTabLoading] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setTabLoading(true);
    const _activeTab = location.pathname.split("/")[2];
    _activeTab ? setActiveTab(_activeTab) : setActiveTab("overview");
    setTabLoading(false);
    // const _activeTab = localStorage.getItem("tab");
    // _activeTab && setActiveTab(JSON.parse(_activeTab));
  }, []);

  // useEffect(() => {
  //   console.log(activeTab);
  //   setTabLoading(true);
  //   localStorage.setItem("tab", JSON.stringify(activeTab));
  //   setTabLoading(false);
  // }, [activeTab]);

  useEffect(() => {
    if (props.huntId) {
      setActiveTab(props.menu.length + 1);
    }
  }, [props.huntId]);

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {props.menu.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            onClick={() => {
              window.location.href = item.link;
              // setActiveTab(index);
              setMobileOpen(false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    _window !== undefined ? () => _window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {isTabletOrMobile && (
            <div
              style={{
                justifyContent: "end",
                alignItems: "center",
                display: "flex",
                width: "20%",
              }}
              onClick={() => {}}
            >
              <IconButton
                // style={{ width: "10%" }}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" }, fontSize: "32px" }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <div
            className="logoImgDiv"
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              width: isTabletOrMobile && "60%",
            }}
          >
            <img src={logo} width={isTabletOrMobile ? "180" : "150"} />
          </div>
          <div
            style={{
              justifyContent: "end",
              alignItems: "center",
              display: "flex",
              width: "20%",
            }}
            onClick={() => {
              localStorage.removeItem("user");
              window.location.reload();
            }}
          >
            <IconButton size="large" aria-label="notifications" color="inherit">
              <LogoutIcon sx={{ fontSize: "32px" }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {tabLoading && <Loader />}
        {!tabLoading && props.huntId
          ? props.ManageHunt
          : !tabLoading &&
            activeTab &&
            props.menu.find((s) => s.path === activeTab).content}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
