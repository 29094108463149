/* eslint-disable */

import TextField from "@mui/material/TextField";
import RowRadioButtonsGroup from "./CorrectOption";

export default function MultipleChoice(props) {
  const maxChoices = 4;

  return (
    <div>
      {Array.from(Array(maxChoices)).map((item, index) => (
        <TextField
          key={index}
          index={index}
          margin="dense"
          id={`${props.options[index].label}_${index}`}
          label={props.options[index].label}
          type="text"
          fullWidth
          variant="filled"
          onChange={props.options[index].onChange}
          value={props.options[index].value}
        />
      ))}
      <br />
      <br />
      <RowRadioButtonsGroup value={props.correct} onChange={props.onChange} />
    </div>
  );
}
