import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export default function ShareButtons(props) {
  // const [postUrl, setPostUrl] = useState("");
  // useEffect(() => {
  //   console.log(`${PUBLIC_URL}/post${props.url.split(GC_URL)[1]}`);
  //   setPostUrl(`${PUBLIC_URL}/post${props.url.split(GC_URL)[1]}`);
  // }, []);

  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2rem",
        backgroundColor: props.hunt.hunt_theme.boxes.bg,
      }}
    >
      <FacebookShareButton
        children={<FacebookIcon round={true} />}
        media={props.url}
        url={props.url}
      />
      <PinterestShareButton
        children={<PinterestIcon round={true} />}
        url={props.url}
        media={props.url}
      />
      <TwitterShareButton
        children={<TwitterIcon round={true} />}
        url={props.url}
        media={props.url}
      />
      <WhatsappShareButton
        children={<WhatsappIcon round={true} />}
        url={props.url}
        media={props.url}
      />
    </div>
  );
}
