/* eslint-disable */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FaPlusCircle } from "react-icons/fa";
import LoadingBtn from "../mui/LoadingBtn";

export default function TaskDialog(props) {
  return (
    <div>
      <Button
        variant="outlined"
        onClick={props.handleClickOpen}
        startIcon={<FaPlusCircle />}
        style={props.item.style}
      >
        {props.item.name}
      </Button>
      <Dialog open={props.open} onClose={props.handleClose} fullWidth>
        <DialogTitle style={{ backgroundColor: "#FABF45", fontWeight: "bold" }}>
          {props.editMode
            ? `Edit ${props.item.name}`
            : `Add ${props.item.name}`}
        </DialogTitle>
        <DialogContent>{props.item.component}</DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#D74230",
              borderColor: "#D74230",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => {
              props.setTaskType("");
              props.setTaskName("");
              props.setTaskScore("");
              props.setTaskAnswer("");
              props.setTaskMultiple([]);
              props.setAutoScore(false);
              props.setLinks({
                text1: "",
                url1: "",
                text2: "",
                url2: "",
                text3: "",
                url3: "",
              });
              props.handleClose();
            }}
          >
            Cancel
          </Button>
          <LoadingBtn
            loading={props.newTaskLoader}
            style={{
              backgroundColor: "#FABF45",
              borderColor: "#FABF45",
              color: "black",
              fontWeight: "bold",
            }}
            onClick={props.editMode ? props.updateTask : props.onAddNewTask}
            txt={props.editMode ? "Update Task" : "Create Task"}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
