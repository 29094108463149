/* eslint-disable */

import FileUpload from "./FileUpload";

const MultipleFiles = (props) => {
  //   const [logoFile, setLogoFile] = useState("");
  //   const [logoFilename, setLogoFilename] = useState("Company Logo");

  //   const [endingBanner, setEndingBanner] = useState("");
  //   const [endingBannerName, setEndingBannerName] = useState("Ending Banner");

  //   const onChange = (e) => {
  //     let _file = files.find((f) => f.id === e.target.id);
  //     _file.setFile(e.target.files[0]);
  //     _file.setFilename(e.target.files[0].name);
  //   };

  //   const files = [
  //     {
  //       id: "1",
  //       file: logoFile,
  //       filename: logoFilename,
  //       setFile: (e) => setLogoFile(e),
  //       setFilename: (e) => setLogoFilename(e),
  //     },
  //     {
  //       id: "2",
  //       file: endingBanner,
  //       filename: endingBannerName,
  //       setFile: (e) => setEndingBannerName(e),
  //       setFilename: (e) => setEndingBannerName(e),
  //     },
  //   ];

  return (
    <div className="container">
      {props.files.map((item, index) => (
        <div key={index}>
          {/* {props.logoFile && index === 0 && (
            <img
              src={`${GC_URL}/${props.logoFile}`}
              style={{ borderRadius: "10px", width: "40px" }}
            />
          )}
          {props.endingBanner && index === 1 && (
            <img
              src={`${GC_URL}/${props.endingBanner}`}
              style={{ borderRadius: "10px", width: "40px", height: "40px" }}
            />
          )} */}
          <FileUpload
            taskThumbnail={props.taskThumbnail}
            onRemoveFile={props.onRemoveFile}
            logoFile={props.logoFile}
            endingBanner={props.endingBanner}
            welcomeBanner={props.welcomeBanner}
            bgImg={props.bgImg}
            key={index}
            item={item}
            onChange={props.onChange}
            id={item.id}
            displayThumbnail={props.displayThumbnail}
          />
          <br />
        </div>
      ))}
    </div>
  );
};

export default MultipleFiles;
