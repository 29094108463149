/* eslint-disable */

import { useState, useEffect } from "react";
import { SERVER_URL, ADMIN_API } from "../../Config";
import ResponsiveTable from "../partials/ResponsiveTable";
import FullScreenDialog from "../mui/FullScreenDialog";
import EditHunt from "./EditHunt";
import Loader from "../partials/Loader";
import LoadingBtn from "../mui/LoadingBtn";

import CreateApiClient from "../../API";

const api = CreateApiClient();

export default function Challenges(props) {
  const [challenges, setChallenges] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editChallenge, setEditChallenge] = useState(null);
  const [page, setPage] = useState(0);
  const [huntsLoading, setHuntsLoading] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(null);
  const [copyLoading, setCopyLoading] = useState(null);
  const [completions, setCompletions] = useState(0);

  useEffect(() => {
    getChallenges();
  }, [page]);

  const getTasks = async (id) => {
    const url = `${SERVER_URL}/${ADMIN_API}/tasks-by-hunt?id=${id}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    return data;
  };

  const getChallenges = async (inPageLoading) => {
    if (page === 0 && !inPageLoading) {
      setHuntsLoading(true);
    } else {
      setMoreLoading(true);
    }
    const url = `${SERVER_URL}/${ADMIN_API}/challenges?page=${page}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    if (data.data.length + challenges.length < data.count) {
      setHasMoreResults(true);
    } else {
      setHasMoreResults(false);
    }
    setChallenges([...challenges, ...data.data]);
    if (page === 0) {
      setHuntsLoading(false);
    } else {
      setMoreLoading(false);
    }
  };

  const tableHeaders = [
    "Logo",
    "Name",
    "Status",
    "Participants",
    // "Completions",
    "Actions",
  ];

  const deleteChallenge = async (challenge) => {
    setDeleteLoading(challenge._id);
    const url = `${SERVER_URL}/${ADMIN_API}/delete-challenge?id=${challenge._id}&page=${page}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.updateData("POST", url, headers, null);
    if (data.data.length + challenges.length < data.count) {
      setHasMoreResults(true);
    } else {
      setHasMoreResults(false);
    }
    setChallenges(data.data);
    if (page === 0) {
      setHuntsLoading(false);
    } else {
      setMoreLoading(false);
    }
    setDeleteLoading(null);
  };

  const copyHunt = async (challenge) => {
    setCopyLoading(challenge._id);
    const url = `${SERVER_URL}/${ADMIN_API}/copy-challenge?id=${challenge._id}&page=${page}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.updateData("POST", url, headers, null);
    if (data.data.length + challenges.length < data.count) {
      setHasMoreResults(true);
    } else {
      setHasMoreResults(false);
    }
    setChallenges(data.data);
    if (page === 0) {
      setHuntsLoading(false);
    } else {
      setMoreLoading(false);
    }
    setCopyLoading(null);
  };

  const openEditDialog = (challenge) => {
    setEditChallenge(challenge);
    setEditOpen(true);
  };

  const manageHunt = (challenge) => {
    window.open(`/hunts/${challenge._id}`, "_blank");
  };

  const handleClose = () => {
    setEditOpen(false);
  };

  const checkCompletions = (challenge) => {
    let count = 0;
    const names = challenge.players.map((player) => player.name);
    names.forEach((name) => {
      const playerCompletions = challenge.submissions?.filter(
        (s) => s.name === name
      ).length;
      if (playerCompletions === challenge.tasks.length) {
        count++;
      }
    });
    return count;
  };

  return (
    <div className="english inpageSection">
      <h1>Hunts</h1>
      <hr />
      {huntsLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <ResponsiveTable
            copyLoading={copyLoading}
            copyHunt={(hunt) => copyHunt(hunt)}
            checkCompletions={(hunt) => checkCompletions(hunt)}
            deleteLoading={deleteLoading}
            headers={tableHeaders}
            data={challenges}
            tableName="challenges"
            deleteChallenge={(challenge) => {
              let confirmed = window.confirm(
                `Are you sure you want to delete ${challenge.hunt_name}?`
              );
              if (confirmed) {
                deleteChallenge(challenge);
              }
            }}
            openEditDialog={(challenge) => openEditDialog(challenge)}
            manageHunt={(challenge) => manageHunt(challenge)}
          />
          <FullScreenDialog
            component={
              <EditHunt user={props.user} editChallenge={editChallenge} />
            }
            open={editOpen}
            handleClose={handleClose}
            headline={`Edit ${editChallenge && editChallenge.hunt_name}`}
          />
          {hasMoreResults && (
            <div style={{ textAlign: "center" }}>
              <LoadingBtn
                style={{
                  // width: isTabletOrMobile ? "50%" : "20%",
                  margin: "10px auto",
                  backgroundColor: "#fabe44",
                  color: "black",
                  borderColor: "#fabe44",
                }}
                txt="Load More"
                loading={moreLoading}
                onClick={() => setPage(page + 1)}
                type="submit"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
