/* eslint-disable */

import { useState, useEffect } from "react";
import { SERVER_URL, ADMIN_API } from "../../Config";
import TaskDialog from "./TaskDialog";
import TaskForm from "./TaskForm";
import { FaCamera, FaVideo, FaQuestionCircle, FaList } from "react-icons/fa";
// import Checkbox from "@mui/material/Checkbox";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
import { useMediaQuery } from "react-responsive";
import DraggableList from "./DraggableList";
import MultiSelectSearch from "../partials/MultiSelectSearch";
import { FaPlusCircle } from "react-icons/fa";
import Button from "@mui/material/Button";
import CreateApiClient from "../../API";
import Loader from "../partials/Loader";

const api = CreateApiClient();

export default function Tasks(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [tasksLoading, setTasksLoading] = useState(false);
  const [error, setError] = useState(false);
  const [taskType, setTaskType] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [taskName, setTaskName] = useState("");
  const [taskScore, setTaskScore] = useState("");
  const [taskIcon, setTaskIcon] = useState("");
  const [taskAnswer, setTaskAnswer] = useState("");
  const [taskMultiple, setTaskMultiple] = useState([]);
  const [autoScore, setAutoScore] = useState(false);
  const [links, setLinks] = useState([]);
  const [linkTxtValues, setLinkTxtValues] = useState({
    text1: "",
    text2: "",
    text3: "",
  });
  const [linkUrlValues, setLinkUrlValues] = useState({
    url1: "",
    url2: "",
    url3: "",
  });

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Thumbnail.png");
  const [oldTasks, setOldTasks] = useState([]);
  const [oldTasksOptions, setOldTasksOptions] = useState([]);
  const [chosenTasksOptions, setChosenTasksOptions] = useState([]);
  const [chosenTasks, setChosenTasks] = useState([]);

  const [optionValue_1, setOptionValue_1] = useState("");
  const [optionValue_2, setOptionValue_2] = useState("");
  const [optionValue_3, setOptionValue_3] = useState("");
  const [optionValue_4, setOptionValue_4] = useState("");

  const [multiOptions, setMultiOptions] = useState([]);

  const [correct, setCorrect] = useState("");
  const [open, setOpen] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [editedTask, setEditedTask] = useState([]);
  const [showInFeed, setShowInFeed] = useState();
  const [taskCategory, setTaskCategory] = useState("Category A");
  const [categories, setCategories] = useState([]);

  const [lang, setLang] = useState("English");

  useEffect(() => {
    getTasks();
  }, []);

  const handleClickOpen = (value) => {
    setOpen(value);
  };

  const languages = [
    {
      name: "Hebrew",
      value: "Hebrew",
    },
    {
      name: "English",
      value: "English",
    },
  ];

  const onLangChange = (e) => {
    setLang(e.target.value);
  };
  const onCategoryChange = (e) => {
    setTaskCategory(e.target.value);
  };
  const handleClose = () => {
    setOpen(null);
    setTaskType("");
    setTaskTitle("");
    setTaskCategory("");
    setTaskName("");
    setTaskScore("");
    setTaskAnswer("");
    setTaskIcon("");
    setShowInFeed(true);
    setTaskMultiple([]);
    setAutoScore(false);
    setFile("");
    setFilename("");
    setLinks([]);
    setLinkUrlValues({
      url1: "",
      url2: "",
      url3: "",
    });

    setLinkTxtValues({
      text1: "",
      text2: "",
      text3: "",
    });
    setCorrect("");
    setOptionValue_1("");
    setOptionValue_2("");
    setOptionValue_3("");
    setOptionValue_4("");
  };

  const handleEditOpen = (task) => {
    setShowInFeed(task.showInFeed);
    setTaskCategory(task.category);
    setEditedTask(task);
    setTaskId(task._id);
    setTaskType(task.type);
    setTaskTitle(task.title ? task.title : "");
    setTaskName(task.name);
    setTaskAnswer(task.answer);
    setLang(task.language);
    setTaskScore(task.score);
    setTaskMultiple([]);
    setAutoScore(task.autoScore);
    setFile("");
    setFilename(task.filename);
    setTaskIcon(task.taskIcon);
    // setLinks(task.links);
    setLinkUrlValues({
      url1: task.links[0].url,
      url2: task.links[1].url,
      url3: task.links[2].url,
    });

    setLinkTxtValues({
      text1: task.links[0].text,
      text2: task.links[1].text,
      text3: task.links[2].text,
    });
    setCorrect(task.correct_option);
    setOptionValue_1(task.options[0].value);
    setOptionValue_2(task.options[1].value);
    setOptionValue_3(task.options[2].value);
    setOptionValue_4(task.options[3].value);
  };

  const onChangeShowInFeed = () => {
    setShowInFeed(!showInFeed);
  };

  const onChangeTaskScore = (e) => {
    setTaskScore(e.target.value);
  };

  const onChangeTaskAnswer = (e) => {
    setTaskAnswer(e.target.value);
  };

  const onChangeTaskOptions = (e) => {
    setTaskMultiple(e.target.value);
  };

  const onChangeTaskTitle = (e) => {
    setTaskTitle(e.target.value);
  };

  const onChangeTaskName = (e) => {
    setTaskName(e.target.value);
  };

  const onChangeAutoScore = () => {
    setAutoScore(!autoScore);
  };

  const onUploadFile = (e) => {
    let _file = files.find((f) => f.id === e.target.id);
    _file.setFile(e.target.files[0]);
    _file.setFilename(e.target.files[0].name);
  };

  const onUrlChange = (e, fieldName) => {
    setLinkUrlValues({ ...linkUrlValues, [fieldName]: e.target.value });
  };

  const onLinkTxtChange = (e, fieldName) => {
    setLinkTxtValues({ ...linkTxtValues, [fieldName]: e.target.value });
  };

  const files = [
    {
      id: "1",
      file: file,
      filename: filename,
      setFile: (e) => setFile(e),
      setFilename: (e) => setFilename(e),
    },
  ];

  const customIcons = [
    {
      value: "standard",
      label: "Standard",
    },
    {
      value: "bonus",
      label: "Bonus",
    },
    {
      value: "location",
      label: "Location",
    },
  ];

  const onCustomIconChange = (e) => {
    setTaskIcon(e);
  };

  const taskOptions = [
    {
      value: "photo",
      name: "Photo Challenge",
      icon: <FaCamera size={25} />,
      style: {
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#D74230",
        borderColor: "#D74230",
        color: "white",
      },
      component: (
        <TaskForm
          onCustomIconChange={onCustomIconChange}
          customIcons={customIcons}
          files={files}
          onUploadFile={onUploadFile}
          text={"Photo Challenge Text"}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          type="photo"
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
    {
      value: "video",
      name: "Video Challenge",
      icon: <FaVideo size={25} />,
      style: {
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#A364D1",
        borderColor: "#A364D1",
        color: "white",
      },
      component: (
        <TaskForm
          onCustomIconChange={onCustomIconChange}
          customIcons={customIcons}
          files={files}
          onUploadFile={onUploadFile}
          text={"Video Challenge Text"}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          type="video"
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
    {
      value: "qa",
      name: "Question & Answer",
      icon: <FaQuestionCircle size={25} />,
      style: {
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#01BF9E",
        borderColor: "#01BF9E",
        color: "white",
      },
      component: (
        <TaskForm
          onCustomIconChange={onCustomIconChange}
          customIcons={[customIcons[0], customIcons[1]]}
          files={files}
          onUploadFile={onUploadFile}
          text={"Qustion Challenge Text"}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          type={"qa"}
          answerText={"Task Answer"}
          taskAnswer={taskAnswer}
          onChangeTaskAnswer={onChangeTaskAnswer}
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
    {
      value: "multi",
      name: "Multiple Choice",
      icon: <FaList size={25} />,
      style: {
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#FABF45",
        borderColor: "#FABF45",
        color: "white",
      },
      component: (
        <TaskForm
          onCustomIconChange={onCustomIconChange}
          customIcons={customIcons}
          files={files}
          onUploadFile={onUploadFile}
          text={"Multiple Choice Challenge Text"}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          multiOptions={multiOptions}
          setMultiOptions={setMultiOptions}
          type="multi"
          value_1={optionValue_1}
          value_2={optionValue_2}
          value_3={optionValue_3}
          value_4={optionValue_4}
          setOptionValue_1={(e) => setOptionValue_1(e.target.value)}
          setOptionValue_2={(e) => setOptionValue_2(e.target.value)}
          setOptionValue_3={(e) => setOptionValue_3(e.target.value)}
          setOptionValue_4={(e) => setOptionValue_4(e.target.value)}
          correct={correct}
          setCorrect={(e) => setCorrect(e.target.value)}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
  ];

  const removeTask = (task, editMode) => {
    if (!editMode) {
      setOldTasks([...oldTasks, task]);
    }
    let filteredTasks = props.tasks.filter((s) => s != task);
    let filteredOldTasks = chosenTasks.filter((s) => s.value != task._id);
    props.setTasks(filteredTasks);
    setChosenTasks(filteredOldTasks);
  };

  const editTask = (task) => {
    setEditMode(true);
    handleEditOpen(task);
    handleClickOpen(task.type);
  };

  const isDuplicate = async (task) => {
    const url = `${SERVER_URL}/${ADMIN_API}/check-duplicate-task?title=${task.title}&category=${task.category}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    return data.duplicate;
  };

  const onAddNewTask = async (type) => {
    let _links = [
      {
        text: linkTxtValues.text1,
        url: linkUrlValues.url1,
      },
      {
        text: linkTxtValues.text2,
        url: linkUrlValues.url2,
      },
      {
        text: linkTxtValues.text3,
        url: linkUrlValues.url3,
      },
    ];

    let obj = {
      type: type,
      title: taskTitle,
      name: taskName,
      category: taskCategory,
      language: lang,
      hunts: [],
      score: taskScore,
      answer: taskAnswer,
      showInFeed: showInFeed,
      icon: taskIcon,
      options: [
        { index: 1, value: optionValue_1 },
        { index: 2, value: optionValue_2 },
        { index: 3, value: optionValue_3 },
        { index: 4, value: optionValue_4 },
      ],
      correct_option: Number(correct),
      autoScore: autoScore,
      filename: filename,
      file: file,
      links: _links,
    };

    const isDup = await isDuplicate(obj);
    if (isDup) {
      window.alert("Duplicate Task! Change name or category.");
      return;
    }

    if (editMode) {
      let taskIndex = props.tasks.indexOf(editedTask);
      let filteredTasks = props.tasks.splice(taskIndex, 1);
      props.setTasks(filteredTasks);
      // props.setTasks([...props.tasks, ...filteredTasks]);
    }
    props.setTasks([...props.tasks, obj]);

    handleClose();
  };

  const getTasks = async () => {
    setTasksLoading(true);
    const url = `${SERVER_URL}/${ADMIN_API}/tasks?options=true`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    setOldTasks(data.data);
    setCategories(data.categories);
    setTasksLoading(false);
  };

  const chooseOldTask = (arr) => {
    setChosenTasks(arr);
  };

  const addOldTasks = () => {
    props.setTasks([...props.tasks, ...chosenTasks]);
    let notAdded = oldTasks.filter((x) => !chosenTasks.includes(x));
    setOldTasks(notAdded);
    setChosenTasks([]);
    setChosenTasksOptions([]);
  };

  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <div style={{ display: "block", width: "100%" }}>
        <div
          style={{
            backgroundColor: "#fabe44",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <h4>Add An Existing Task:</h4>
          {/* <MultiSelect
          data={oldTasks}
          options={oldTasks}
          label={"Tasks"}
          style={{ width: "90%" }}
          handleChange={(e) => chooseOldTask(e)}
          value={chosenTasks}
        /> */}

          <div
            style={{
              display: !isTabletOrMobile && "inline-flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: isTabletOrMobile ? "100%" : "50%",
                marginRight: !isTabletOrMobile && "5px",
                maxWidth: "500px",
              }}
            >
              <MultiSelectSearch
                // data={oldTasks}
                options={oldTasks}
                label={"Choose Tasks"}
                handleChange={(e) => chooseOldTask(e)}
                selected={chosenTasks}
              />
            </div>
            {isTabletOrMobile && <br />}
            <Button
              variant="outlined"
              onClick={addOldTasks}
              startIcon={<FaPlusCircle />}
              style={{
                backgroundColor: "#131313",
                color: "white",
                borderColor: "#131313",
              }}
            >
              Add Tasks
            </Button>
          </div>
        </div>
        <br />
        <br />
        <div
          style={{
            display: isTabletOrMobile ? "block" : "flex",
            width: "100%",
            justifyContent: "center",
          }}
          className="flexForm flexButtons"
        >
          {taskOptions.map((item, index) => (
            <TaskDialog
              handleClickOpen={() => handleClickOpen(item.value)}
              handleClose={handleClose}
              open={open === item.value}
              key={index}
              item={item}
              onAddNewTask={() => onAddNewTask(item.value)}
              setOpen={setOpen}
              setTaskType={setTaskType}
              setTaskName={setTaskName}
              setTaskScore={setTaskScore}
              setTaskAnswer={setTaskAnswer}
              setTaskMultiple={setTaskMultiple}
              setAutoScore={setAutoScore}
              setLinks={setLinks}
              // editMode={editMode}
            />
          ))}
        </div>
        <div
          style={{ display: "flex", width: "100%" }}
          className="flexForm"
        ></div>
      </div>
      <br />
      <div className="tasksTable">
        {props.tasksLoading ? (
          <div
            style={{
              height: "10vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <DraggableList
            editTask={null}
            // editTask={(task) => editTask(task)}
            removeLoading={null}
            tasks={props.tasks}
            setTasks={props.setTasks}
            removeTask={(task) => {
              let confirmed = window.confirm(
                `Are you sure you want to remove ${task.title} from this hunt?`
              );
              if (confirmed) {
                removeTask(task, props.editMode);
              }
            }}
            taskOptions={taskOptions}
          />
        )}
      </div>
    </div>
  );
}
