import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

export default function LoadingBtn(props) {
  return (
    <LoadingButton
      type={props.type}
      loading={props.loading}
      variant="outlined"
      style={props.style}
      onClick={props.onClick}
      loadingPosition={"end"}
      endIcon={props.customIcon ? props.customIcon : <SendIcon />}
    >
      {props.txt}
    </LoadingButton>
  );
}
