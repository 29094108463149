/* eslint-disable */

import { Fragment } from "react";
import { GC_URL } from "../../Config";

const FileUpload = (props) => {
  return (
    <Fragment>
      <div className="container">
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {props.item.id === "1" && <span>Wide logo file (600 X 185)</span>}
          {props.item.id === "1_1" && <span>Welcome banner file (16:9)</span>}
          {props.item.id === "1_2" && <span>Square logo file (600 X 600)</span>}
          {props.item.id === "2" && (
            <span>Ending banner file (Square - 800 X 800)</span>
          )}
          {props.item.id === "3" && (
            <span>Background image file (1200 X 800)</span>
          )}
          {props.item.filename && (
            <>
              {props.displayThumbnail && (
                <img
                  src={
                    props.taskThumbnail
                      ? props.taskThumbnail
                      : `${GC_URL}/${props.item.filename}`
                  }
                  style={{ width: "80px", borderRadius: "2px" }}
                />
              )}
              <div
                onClick={(e) => {
                  let confirmed = window.confirm(
                    "Are you sure you want to remove file?"
                  );
                  if (confirmed) {
                    props.onRemoveFile(e);
                  }
                }}
                id={`${props.item.id}_${props.item.id}`}
                className="pointer"
                style={{
                  marginBottom: "10px",
                  width: "200px",
                  height: "30px",
                  textAlign: "center",
                  backgroundColor: "black",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "22px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                X Remove Image
              </div>
            </>
          )}
        </div>
        <div
          className="file-upload-wrapper"
          data-text={
            props.item.filename && props.item.filename.length > 16
              ? props.item.filename.substring(0, 16) + "..."
              : props.item.filename
          }
        >
          <input
            type="file"
            id={props.item.id}
            onChange={props.onChange}
            name="file-upload-field"
            className="file-upload-field"
            value=""
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FileUpload;
