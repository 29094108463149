import LoaderIcon from "react-loader-icon";

export default function Loader(props) {
  return (
    <div style={{ display: "block" }}>
      <LoaderIcon type={"spin"} />
      {/* <h4 style={{ textAlign: "center" }}>
        {props.lang === "Hebrew" ? "טוען..." : "Loading..."}
      </h4> */}
    </div>
  );
}
