export default function UploadFile(props) {
  return (
    <form
      onSubmit={props.submitForm}
      style={{
        display: "inline-flex",
        width: "100%",
        justifyContent: "space-around",
        alignItems: "end",
      }}
      id="uploadMediaForm"
    >
      <br />
      <input
        accept={props.type === "video" ? "video/*" : "image/*"}
        name="image"
        id="media-upload"
        type="file"
        onChange={props.onSelectFile}
        // onChange={(e) => {
        //   const file = e.target.files[0];
        //   const reader = new FileReader();
        //   reader.onload = (event) => {
        //     const image = new Image();
        //     image.onload = () => {
        //       const canvas = document.createElement("canvas");
        //       const context = canvas.getContext("2d");
        //       const maxSize = Math.min(image.width, image.height);
        //       canvas.width = maxSize;
        //       canvas.height = maxSize;
        //       context.drawImage(
        //         image,
        //         (image.width - maxSize) / 2,
        //         (image.height - maxSize) / 2,
        //         maxSize,
        //         maxSize,
        //         0,
        //         0,
        //         maxSize,
        //         maxSize
        //       );
        //       canvas.toBlob((blob) => {
        //         props.onSelectFile(blob);
        //       }, file.type);
        //     };
        //     image.src = event.target.result;
        //   };
        //   reader.readAsDataURL(file);
        // }}
      />
      {props.label}
    </form>
  );
}
