/* eslint-disable */

import { useState, useEffect } from "react";
import EditDetails from "./EditDetails";
import thumbnail from "../../assets/images/clg_logo_square.png";

export default function EditHunt(props) {
  const [activeSection, setActiveSection] = useState([]);
  const [file, setFile] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (selectedFile) {
      // create the preview
      const objectUrl = window.URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => window.URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setFile(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  const formSections = [
    {
      name: "hunt details",
      form: (
        <EditDetails
          challenge={props.editChallenge}
          submitForm={props.submitForm}
          thumbnail={thumbnail}
          preview={preview}
          selectedFile={selectedFile}
          onSelectFile={(e) => onSelectFile(e)}
          errorMessage={props.errorMessage}
          file={file}
          user={props.user}
        />
      ),
    },
  ];

  useEffect(() => {
    setActiveSection(formSections[0]);
  }, []);

  return (
    <div className="english inpageSection">
      <h1>Edit Challenge</h1>
      <div className="formSection">
        <div className="headline">
          <h3 className="capitalize">{activeSection.name}</h3>
        </div>
        <div className="wrapper">{activeSection.form}</div>
      </div>
    </div>
  );
}
