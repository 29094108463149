import { SERVER_URL, GAME_API } from "../Config";
import CreateApiClient from "../API";

const api = CreateApiClient();

export const verifyPlayer = async (id, name) => {
  const url = `${SERVER_URL}/${GAME_API}/verify-player?id=${id}&name=${name}`;

  const data = await api.fetchData("GET", url, {}, null);
  return data;
};
