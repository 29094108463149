import React from "react";

const DownloadCSV = ({ data, filename }) => {
  const downloadCSV = () => {
    // Convert data to CSV format
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(",").replace(/undefined/g, "X"));
    }

    const csvContent = csvRows.join("\n");

    // Create a Blob from the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with a filename
    link.setAttribute("download", filename);

    // Create a URL for the Blob and set it as the href attribute
    link.href = URL.createObjectURL(blob);

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <button
      onClick={downloadCSV}
      style={{
        backgroundColor: "#F6CD65",
        borderRadius: "4px",
        padding: "5px 15px",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      Download CSV
    </button>
  );
};

export default DownloadCSV;
