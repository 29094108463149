/* eslint-disable */

import TextField from "@mui/material/TextField";

export default function PlayerNames(props) {
  return (
    <div>
      {props.options.slice(0, 2).map((item, index) => (
        <TextField
          key={index}
          sx={{ width: "30%", margin: "2px" }}
          variant="outlined"
          label={item.name}
          onChange={item.setName}
          value={item.value}
          placeholder={item.placeholder}
        />
      ))}
    </div>
  );
}
